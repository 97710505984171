import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';

const technology: IImageWithDescription[] = [
	{
		image: 'safety.svg',
		name: 'home.technology.safety.name',
		description: 'home.technology.safety.description'
	},
	{
		image: 'cost.svg',
		name: 'home.technology.anonymity.name',
		description: 'home.technology.anonymity.description'
	},
	{
		image: 'cost.svg',
		name: 'home.technology.cost.name',
		description: 'home.technology.cost.description'
	}
];

export default technology;
