<template>
	<section id="technology">
		<v-container ref="container">
			<v-row>
				<v-col cols="12">
					<shared-heading
						heading="home.technology.heading"
						subheading="home.technology.subheading">
					</shared-heading>
				</v-col>
				<v-col cols="12" class="box box--line-blue" ref="blockchain">
					<v-col cols="12" class="box-header">
						<h3>{{ $t('home.technology.blockchain') }}</h3>
						<p>{{ $t('home.technology.blockchainSubheader') }}</p>
						<svg
							width="66"
							height="67"
							viewBox="0 0 66 67"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							class="vector vector--first">
							<circle
								cx="33.0001"
								cy="33.4445"
								r="32.0001"
								fill="white"
								stroke="#59C9EB"
								stroke-width="2" />
							<circle cx="33" cy="33.4443" r="9" fill="#59C9EB" />
						</svg>
					</v-col>
					<v-col cols="12" class="box-description">
						<v-row>
							<v-col
								v-for="(technology, index) in technologies"
								:key="index"
								md="4"
								sm="12"
								class="technologies">
								<shared-box-with-icon
									catalog="technology"
									:item="technology"></shared-box-with-icon>
							</v-col>
						</v-row>
					</v-col>
				</v-col>
				<v-col cols="12" class="box box--line-purple" ref="internet">
					<v-col cols="12" class="box-header">
						<h3>{{ $t('home.technology.internet') }}</h3>
						<p>{{ $t('home.technology.internetSubheader') }}</p>
						<svg
							width="66"
							height="67"
							viewBox="0 0 66 67"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							class="vector vector--second">
							<circle
								cx="33.0001"
								cy="33.4445"
								r="32.0001"
								fill="white"
								stroke="#59C9EB"
								stroke-width="2" />
							<circle cx="33" cy="33.4443" r="9" fill="#59C9EB" />
						</svg>
					</v-col>
					<v-col cols="12" md="4" class="box-description">
						<p>{{ $t('home.technology.internetDescription') }}</p>
					</v-col>
				</v-col>
				<v-col cols="12" class="box box--skey" ref="skey">
					<v-col cols="12" class="box-header">
						<img
                width="188"
                height="82"
							:data-image="images.skey"
							src=""
							alt="SmartKey Technology" />
						<h4>{{ $t('home.technology.smartKeyTechnology') }}</h4>
						<p>{{ $t('home.technology.smartKeySubheader') }}</p>
						<svg
							width="66"
							height="67"
							viewBox="0 0 66 67"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							class="vector vector--third">
							<circle
								cx="33.0001"
								cy="33.4445"
								r="32.0001"
								fill="white"
								stroke="#59C9EB"
								stroke-width="2" />
							<circle cx="33" cy="33.4443" r="9" fill="#59C9EB" />
						</svg>
					</v-col>
					<v-col cols="12" md="8" class="box-description">
						<p>{{ $t('home.technology.smartKeyDescription') }}</p>
						<div class="text-left">
							<v-btn
								href="https://skey.network/"
								target="_blank"
								class="white--text"
								rounded
								>skey.network
								<v-icon>{{ arrow }}</v-icon></v-btn
							>
						</div>
					</v-col>
				</v-col>
			</v-row>
		</v-container>
		<div class="circles">
			<div class="circle circle--first">
				<shared-circle-bottom-left
					class="bl"></shared-circle-bottom-left>
				<shared-circle-bottom-right
					class="br"></shared-circle-bottom-right>
				<shared-circle-top-left class="tl"></shared-circle-top-left>
				<shared-circle-top-right class="tr"></shared-circle-top-right>
			</div>
			<div class="circle circle--second">
				<shared-circle-bottom-left
					class="bl"></shared-circle-bottom-left>
				<shared-circle-bottom-right
					class="br"></shared-circle-bottom-right>
				<shared-circle-top-left class="tl"></shared-circle-top-left>
				<shared-circle-top-right class="tr"></shared-circle-top-right>
			</div>
			<div class="circle circle--third">
				<shared-circle-bottom-left
					class="bl"></shared-circle-bottom-left>
				<shared-circle-bottom-right
					class="br"></shared-circle-bottom-right>
				<shared-circle-top-left class="tl"></shared-circle-top-left>
				<shared-circle-top-right class="tr"></shared-circle-top-right>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import SharedHeading from '@/components/shared/SharedHeading.vue';
import SharedBoxWithIcon from '@/components/shared/SharedBoxWithIcon.vue';
import SharedCircleBottomLeft from '@/components/shared/circles/SharedCircleBottomLeft.vue';
import SharedCircleBottomRight from '@/components/shared/circles/SharedCircleBottomRight.vue';
import SharedCircleTopLeft from '@/components/shared/circles/SharedCircleTopLeft.vue';
import SharedCircleTopRight from '@/components/shared/circles/SharedCircleTopRight.vue';
import technology from '@/data/home/technology.data';
import { mdiChevronRight } from '@mdi/js';
import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';
import Vue from 'vue';
import { gsap } from '@/main';

export default Vue.extend({
	name: 'HomeTechnology',
	data: (): {
		arrow: string;
		durationX: number;
		durationY: number;
		stopColorTop: string;
		stopColorBottom: string;
		images: { [key: string]: string };
		technologies: IImageWithDescription[];
		bottomLeftTimeLine: gsap.core.Timeline;
		bottomRightTimeLine: gsap.core.Timeline;
		topLeftTimeLine: gsap.core.Timeline;
		topRightTimeLine: gsap.core.Timeline;
	} => ({
		arrow: mdiChevronRight,
		durationX: 3,
		durationY: 5,
		stopColorTop: 'rgba(145, 103, 242, 0.22)',
		stopColorBottom: 'rgba(60, 133, 251, 0.22)',
		images: {
			skey: require('../../assets/images/home/technology/skey.svg')
		},
		technologies: technology,
		bottomLeftTimeLine: {} as gsap.core.Timeline,
		bottomRightTimeLine: {} as gsap.core.Timeline,
		topLeftTimeLine: {} as gsap.core.Timeline,
		topRightTimeLine: {} as gsap.core.Timeline
	}),
	components: {
		SharedCircleBottomLeft,
		SharedCircleBottomRight,
		SharedCircleTopLeft,
		SharedCircleTopRight,
		SharedBoxWithIcon,
		'shared-heading': SharedHeading
	},
	methods: {
		initializeTimeline(
			blockchainContainer: HTMLElement,
			internetContainer: HTMLElement,
			skeyContainer: HTMLElement
		): gsap.core.Timeline {
			return gsap.timeline({
				scrollTrigger: {
					trigger: '#technology',
					start: 'top center',
					end: `${
						blockchainContainer.offsetHeight +
						internetContainer.offsetHeight +
						skeyContainer.offsetHeight / 1.25
					} center`,
					scrub: 0.5,
					invalidateOnRefresh: true
				}
			});
		}
	},
	mounted(): void {
		const blockchainContainer = this.$refs.blockchain as HTMLElement;
		const internetContainer = this.$refs.internet as HTMLElement;
		const skeyContainer = this.$refs.skey as HTMLElement;
		const container = this.$refs.container as HTMLElement;

		const offsetX = -container.offsetLeft / 2;
		const offsetY =
			blockchainContainer.offsetHeight +
			internetContainer.offsetHeight / 2;

		this.bottomLeftTimeLine = this.initializeTimeline(
			blockchainContainer,
			internetContainer,
			skeyContainer
		);
		this.bottomRightTimeLine = this.initializeTimeline(
			blockchainContainer,
			internetContainer,
			skeyContainer
		);
		this.topLeftTimeLine = this.initializeTimeline(
			blockchainContainer,
			internetContainer,
			skeyContainer
		);
		this.topRightTimeLine = this.initializeTimeline(
			blockchainContainer,
			internetContainer,
			skeyContainer
		);

		// Bottom left
		this.bottomLeftTimeLine.to('.bl', {
			x: offsetX,
			duration: this.durationX
		});
		this.bottomLeftTimeLine.to('.bl', {
			y: offsetY,
			duration: this.durationY
		});
		this.bottomLeftTimeLine.to('.bl', {
			duration: 1
		});
		this.bottomLeftTimeLine.to('.bl', {
			stopColor: this.stopColorTop
		});
		this.bottomLeftTimeLine.to('.bl', {
			stopColor: this.stopColorBottom
		});

		// Bottom right
		this.bottomRightTimeLine.to('.br', {
			x: offsetX,
			duration: this.durationX
		});
		this.bottomRightTimeLine.to('.br', {
			y: offsetY + 110,
			duration: this.durationY
		});
		this.bottomRightTimeLine.to('.br', {
			duration: 1
		});
		this.bottomRightTimeLine.to('.br', {
			stopColor: this.stopColorTop
		});
		this.bottomRightTimeLine.to('.br ', {
			stopColor: this.stopColorBottom
		});

		// Top left
		this.topLeftTimeLine.to('.tl', {
			x: offsetX - 365,
			duration: this.durationX
		});
		this.topLeftTimeLine.to('.tl', {
			y: offsetY,
			duration: this.durationY
		});
		this.topLeftTimeLine.to('.tl', {
			rotateY: -180,
			duration: 1
		});
		this.topLeftTimeLine.to('.tl stop:first-child', {
			stopColor: this.stopColorTop
		});
		this.topLeftTimeLine.to('.tl stop:last-child', {
			stopColor: this.stopColorBottom
		});

		// Top right
		this.topRightTimeLine.to('.tr', {
			x: offsetX - 365,
			duration: this.durationX
		});
		this.topRightTimeLine.to('.tr', {
			y: offsetY + 110,
			duration: this.durationY
		});
		this.topRightTimeLine.to('.tr', {
			rotateY: 180,
			duration: 1
		});
		this.topRightTimeLine.to('.tr', {
			stopColor: this.stopColorTop
		});
		this.topRightTimeLine.to('.tr', {
			stopColor: this.stopColorBottom
		});

		this.$store.dispatch('refreshScrollInstances', [
			this.bottomLeftTimeLine.scrollTrigger,
			this.bottomRightTimeLine.scrollTrigger,
			this.topRightTimeLine.scrollTrigger,
			this.topLeftTimeLine.scrollTrigger
		]);
	},
	beforeDestroy() {
		this.$store.commit('killScrollInstances', [
			this.bottomLeftTimeLine,
			this.bottomRightTimeLine,
			this.topRightTimeLine,
			this.topLeftTimeLine
		]);
	}
});
</script>

<style lang="scss">
@import 'src/styles/mixins';

#technology {
	position: relative;
	padding-top: 5.5rem;
	overflow: hidden;

	p {
		line-height: 1.675rem;
		margin: 0;
	}

	.box {
		@include z-index(relative, 1);
		padding: 0 0 0 6.125rem;

		&.box--line-blue,
		&.box--line-purple {
			&::after {
				@include set-position(absolute, 4.125rem, auto, 0, 3rem);
				@include size(2px, 100%);
				content: '';
				background: rgb(111, 182, 183);
				background: linear-gradient(
					180deg,
					rgba(111, 182, 183, 1) 0%,
					rgba(89, 201, 235, 1) 100%
				);
			}
		}

		&.box--line-purple::after {
			background: rgb(89, 201, 235);
			background: linear-gradient(
				180deg,
				rgba(89, 201, 235, 1) 0%,
				rgba(181, 91, 238, 1) 100%
			);
		}
	}

	.box-header {
		margin-bottom: 4rem;

		h3 {
			@include font-size-weight(2.5rem, 700);
			line-height: 2.5rem;
			margin: 0 0 2rem;
		}

		h4 {
			@include font-size-weight(1.375rem, 700);
			line-height: 1.675rem;
			margin: 1rem 0 1rem;
		}

		.vector {
			@include set-position(absolute, 0, auto, auto, 1rem);

			&.vector--first {
				circle {
					&:first-of-type {
						stroke: #96d4d1;
					}

					&:last-of-type {
						fill: #96d4d1;
					}
				}
			}

			&.vector--third {
				top: 1rem;

				circle {
					&:first-of-type {
						stroke: #b45bee;
					}

					&:last-of-type {
						fill: #b45bee;
					}
				}
			}
		}
	}

	.box-description {
		margin-bottom: 10rem;

		p {
			font-size: 0.875rem;
		}
	}

	.v-btn {
		@include size(15rem, 3.5rem !important);
		margin-top: 5rem;
		background: linear-gradient(
			90deg,
			#3c85fb 27.24%,
			#b35bee 115.72%
		) !important;
	}

	.circles {
		.circle {
			@include size(53rem, 30rem);
			position: absolute;
			top: 27.5rem;
			right: -40rem;
			z-index: 0;

			&.circle--first {
				right: 20rem;

				.tr {
					path {
						fill: url('#gradient-blue');
					}
				}

				.br {
					path {
						fill: url('#gradient-light-blue');
					}
				}
			}

			&.circle--second {
				right: -10rem;

				.tr {
					path {
						fill: url('#gradient-light-blue');
					}
				}

				.br {
					path {
						fill: url('#gradient-blue');
					}
				}
			}

			&.circle--third {
				.tl {
					path {
						fill: url('#gradient-blue');
					}
				}
			}
		}

		svg {
			@include size(15rem, 15rem);
			position: absolute;
			top: 0;
			right: 0;

			&.bl {
				right: 37.85rem;
				top: 15rem;
			}

			&.br {
				right: 22.85rem;
				top: 15rem;
			}

			&.tl {
				right: 15rem;
			}

			&.tr {
			}
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		padding-top: 3rem;

		.box {
			padding-left: 4rem;

			&.box--line-blue::after,
			&.box--line-purple::after {
				left: 2.2rem;
				top: 2.75rem;
			}
		}

		.box-header {
			margin-bottom: 1rem;

			h3 {
				font-size: 1.75rem;
				line-height: 1.75rem;
				margin: 0 0 1rem;
			}

			.vector {
				@include size(2.5rem, 2.5rem);
				top: 0.25rem;

				&.vector--third {
					top: 1.75rem;
				}
			}
		}

		.box-description {
			margin-bottom: 3rem;
		}

		.v-btn {
			margin-top: 3rem;
		}

		.text-left {
			text-align: center !important;
		}
	}

	@media screen and (max-width: 600px) {
		.technologies {
			@include size-flex(100%);
		}
	}
}
</style>
