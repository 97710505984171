<template>
	<section id="partners">
		<v-container>
			<v-row justify="center">
				<v-col cols="12">
					<shared-heading
						heading="home.partners.heading"
						subheading="home.partners.subheading">
					</shared-heading>
				</v-col>
				<v-col
					v-for="(partner, index) in partners"
					:key="index"
					md="4"
					sm="6"
					class="partners text-center">
					<div class="image">
						<img
							:src="
								require(`@/assets/images/home/partners/${partner.image}`)
							"
							:alt="partner.image" />
					</div>
					<h4 class="text-uppercase">{{ $t(partner.name) }}</h4>
					<p>{{ $t(partner.description) }}</p>
				</v-col>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="ts">
import SharedHeading from '@/components/shared/SharedHeading.vue';
import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';
import partners from '@/data/home/partners.data';

export default {
	name: 'HomePartners',
	data: (): { partners: IImageWithDescription[] } => ({
		partners
	}),
	components: {
		'shared-heading': SharedHeading
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

#partners {
	background-color: #fafafa;
	box-shadow: inset 0 5px 30px -20px #ccc, inset 0 -5px 30px -20px #ccc;
	padding-top: 5rem;
	padding-bottom: 2rem;

	.partners {
		.image {
			height: 8rem;
			position: relative;

			img {
				@include set-position(absolute, 50%, auto, auto, 50%);
				transform: translate(-50%, -50%);
				max-width: 100%;
			}
		}

		h4 {
			@include font-size-weight(0.875rem, 600);
			color: var(--secondary);
			line-height: 1.25rem;
			letter-spacing: 2px;
			margin: 0 0 1rem;
		}

		p {
			@include font-size-color(0.75rem, rgba(#333333, 0.65));
			width: 11rem;
			margin: 0 auto;
			line-height: 1.35rem;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		padding: 3rem 0;
	}

	@media screen and (max-width: 560px) {
		.col-md-4 {
			@include size-flex(100%);
		}
	}
}
</style>
