<template>
	<section id="learn">
		<v-container>
			<v-row>
				<v-col cols="12" md="4">
					<shared-heading
						heading="home.learn.heading"
						subheading="home.learn.subheading"
						additionalClasses="white--text text-left">
					</shared-heading>
					<p v-html="$t('home.learn.description')"></p>
					<img
              width="0"
              height="0"
						:data-image="images.book"
						src=""
						alt="E-Book"
						v-show="this.$i18n.locale === 'pl'" />
					<img
              width="0"
              height="0"
						:data-image="images.bookEn"
						src=""
						alt="E-Book"
						v-show="this.$i18n.locale === 'en'" />
				</v-col>
			</v-row>
		</v-container>
		<img
        width="0"
        height="0"
			:data-image="images.background"
			src=""
			alt="Background"
			class="background" />
	</section>
</template>

<script lang="ts">
import SharedHeading from '@/components/shared/SharedHeading.vue';

export default {
	name: 'HomeLearn',
	data: (): {
		images: { [key: string]: string };
	} => ({
		images: {
			background: require('../../assets/images/home/learn/background.webp'),
			book: require('../../assets/images/home/learn/ebook.webp'),
			bookEn: require('../../assets/images/home/learn/ebook-en.webp')
		}
	}),
	components: {
		'shared-heading': SharedHeading
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

#learn {
	background-color: var(--primary);
	color: var(--white);
	position: relative;

	.row {
		position: relative;
		min-height: 34rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.container {
		@include z-index(relative, 1);
	}

	img:not(.background) {
		@include set-position(absolute, auto, 0.75rem, 0, auto);
		z-index: 1;
	}

	.background {
		@include set-position(absolute, 50%, auto, auto, 50%);
		@include max-size(100%, 100%);
		transform: translate(-50%, -50%);
		z-index: 0;
	}

	p {
		line-height: 2rem;
	}

	@media #{map-get($display-breakpoints, 'md-and-down')} {
		img {
			max-width: 25rem;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		text-align: center;
		overflow: hidden;

		.row {
			min-height: auto;
			padding-top: 3rem;
			padding-bottom: 0;
		}

		.col-12 {
			padding-bottom: 0;
		}

		.heading {
			text-align: center !important;
		}

		img:not(.background) {
			position: relative;
			top: 0;
			right: 0;
			margin: 3rem auto 0;
			display: block;
			max-width: 20rem;
		}

		.background {
			top: -10rem;
			transform: translate(-50%, 0);
		}
	}
}
</style>
