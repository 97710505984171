import { IEducation } from '@/interfaces/education.interface';

const partners: IEducation[] = [
	{
		image: 'sgh.svg',
		name: 'home.education.sgh.name',
		description: 'home.education.sgh.description',
		link: '/'
	},
	{
		image: 'piit.svg',
		name: 'home.education.piit.name',
		description: 'home.education.piit.description',
		link: '/'
	},
	{
		image: 'uwm.svg',
		name: 'home.education.uwm.name',
		description: 'home.education.uwm.description',
		link: '/'
	}
];

export default partners;
