<template>
	<header
		:class="{
			sticky: header.sticky,
			subpage: $route.name !== 'Home',
			'show-mobile-menu': header.toggleMenu
		}">
		<v-container>
			<v-row
				justify="space-between"
				align="center"
				v-if="$vuetify.breakpoint.smAndDown">
				<router-link to="/">
					<shared-logo aria-label="Caruma"></shared-logo>
				</router-link>
				<span class="menu-button" @click="toggleMenu">
					<span></span>
					<span></span>
					<span></span>
				</span>
			</v-row>
			<v-row justify="space-between" align="center" class="header__menu">
				<router-link to="/">
					<shared-logo aria-label="Caruma"></shared-logo>
				</router-link>
				<ul class="menu">
					<li v-for="(link, index) in menu" :key="index">
						<router-link
							:to="link.to"
							@click.native="hideMenuOnClick"
							>{{ $t(`menu.${link.name}`) }}</router-link
						>
					</li>
					<li>
						<a
							v-bind:href="DPP_PER_LANGUAGE_MAP[$i18n.locale]"
							target="_blank"
							>DPP</a
						>
					</li>
				</ul>
				<div>
					<ul class="language">
						<li
							v-for="(language, index) in languages"
							:key="index"
							:class="{
								active: $i18n.locale === language
							}">
							<span
								class="text-uppercase"
								@click="changeLanguage(language)"
								>{{ language }}</span
							>
						</li>
					</ul>
					<ul class="socials">
						<li v-for="(social, index) in socials" :key="index">
							<a
								:href="social.link"
								target="_blank"
								:aria-label="social.link"
								><v-icon>{{ social.icon }}</v-icon></a
							>
						</li>
					</ul>
				</div>
			</v-row>
		</v-container>
	</header>
</template>

<script lang="ts">
import Vue from 'vue';
import menu from '@/data/menu.data';
import socials from '@/data/socials.data';
import { ISocial } from '@/interfaces/social.interface';
import { ILink } from '@/interfaces/link.interface';
import { mapGetters, mapMutations } from 'vuex';
import SharedLogo from '@/components/shared/SharedLogo.vue';
import { DPP_PER_LANGUAGE_MAP } from '@/constants/dpp';

export default Vue.extend({
	name: 'TheNavbar',
	components: {
		SharedLogo
	},
	computed: {
		...mapGetters({
			header: 'header'
		})
	},
	data: (): {
		menu: ILink[];
		socials: ISocial[];
		languages: string[];
		DPP_PER_LANGUAGE_MAP: Record<string, string>;
	} => ({
		menu,
		socials,
		languages: new Array<string>('pl', 'en'),
		DPP_PER_LANGUAGE_MAP
	}),
	methods: {
		...mapMutations({
			toggleMenu: 'toggleMenu',
			hideMenuOnClick: 'hideMenuOnClick'
		}),
		changeLanguage(language: string): void {
			this.$i18n.locale = language;
			window.localStorage.setItem('lang', language);
		}
	}
});
</script>

<style lang="scss">
@import 'src/styles/mixins';

header {
	@include set-position(absolute, 0, 0, auto, 0);
	z-index: 999;

	&.subpage {
		ul.menu li a.router-link-active,
		ul.language li.active span,
		ul.socials li .v-icon {
			color: var(--black) !important;
		}

		.logo .text {
			fill: var(--black);
		}

		.menu-button span {
			background-color: var(--black);
		}
	}

	&.sticky {
		background-color: var(--primary);
		position: fixed;
		animation: animate-menu-down 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		box-shadow: 0 0.125rem 0.375rem -0.125rem var(--black);

		.row {
			background-color: var(--primary);
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		ul.menu li a.router-link-active,
		ul.language li.active span,
		ul.socials li .v-icon {
			color: var(--white) !important;
		}

		.logo .text {
			fill: var(--white);
		}

		.menu-button span {
			background-color: var(--white);
		}
	}

	&.show-mobile-menu {
		position: fixed;

		.row {
			background-color: var(--primary);
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		ul.menu li a.router-link-active,
		ul.language li.active span,
		ul.socials li .v-icon {
			color: var(--white) !important;
		}

		.logo .text {
			fill: var(--white);
		}

		.menu-button {
			span {
				background-color: var(--white);

				&:first-of-type {
					transform: rotate(45deg);
					top: 1rem;
				}

				&:nth-of-type(2) {
					opacity: 0;
				}

				&:last-of-type {
					transform: rotate(-45deg);
					top: 1rem;
				}
			}
		}

		.header__menu {
			top: 4.8125rem;
		}
	}

	.row {
		padding: 2rem 0.75rem;

		> div {
			ul {
				display: inline-block;
			}
		}
	}

	.menu-button {
		@include size(2rem, 2rem);
		position: relative;
		cursor: pointer;
		margin-top: -0.5rem;

		span {
			@include size(2rem, 0.125rem);
			@include set-position(absolute, 0.5rem, 0, auto, auto);
			background-color: var(--white);
			transition: all 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
			opacity: 1;

			&:nth-of-type(2) {
				top: 1rem;
			}

			&:nth-of-type(3) {
				top: 1.5rem;
			}
		}
	}

	ul {
		list-style: none;

		li {
			display: inline-block;
		}

		&.menu,
		&.language {
			li {
				margin-right: 2rem;

				&:last-of-type {
					margin-right: 0;
				}
			}

			a,
			span {
				@include font-size-weight(0.875rem, 700);
				color: rgb(110, 130, 142) !important;
				line-height: 2.75rem;
				cursor: pointer;
			}
		}

		&.language {
			li {
				margin-right: 0.5rem;

				&.active {
					span {
						color: var(--white) !important;
					}
				}
			}
		}

		&.socials {
			margin-left: 1.5rem;

			li {
				margin-right: 0.5rem;

				&:last-of-type {
					margin-right: 0;
				}

				.v-icon {
					color: var(--white) !important;
				}
			}
		}
	}

	@include keyframes(animate-menu-down) {
		0% {
			top: -6.5rem;
		}
		100% {
			top: 0;
		}
	}

	@media #{map-get($display-breakpoints, 'md-and-down')} {
		ul.menu li {
			margin-right: 0.75rem;
		}

		ul.socials {
			margin-left: 1rem;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		.row {
			&:not(.header__menu) {
				@include z-index(relative, 1);
			}

			&.header__menu {
				justify-content: normal !important;
			}
		}

		.header__menu {
			@include set-position(fixed, -200vh, 0, auto, 0);
			@include size(100%, calc(100vh - 4.8125rem));
			background-color: var(--primary);
			margin: 0 !important;
			z-index: 0;
			overflow-y: scroll;
			transition: top 0.67s cubic-bezier(0.785, 0.135, 0.15, 0.86);

			> a {
				display: none;
			}

			.menu {
				@include size-flex(100%);

				li {
					display: block;

					a {
						@include font-size-color(
							1.25rem,
							var(--white) !important
						);
						line-height: 2.75rem;
					}
				}
			}

			> div {
				@include size-flex(100%);

				ul {
					display: block;

					&.language {
						margin: 2rem 0;
					}

					&.socials {
						margin: 0 0 1rem;
					}
				}
			}
		}
	}
}
</style>
