import { IForm } from '@/interfaces/form.interface';
import { LocaleMessages } from 'vue-i18n';

const state: IForm = {
	valid: false,
	firstname: '',
	nameRules: [(v: string): boolean | string | LocaleMessages => !!v.trim()],
	email: '',
	emailRules: [
		(v: string): boolean | string | LocaleMessages => !!v.trim(),
		(v: string): boolean | string | LocaleMessages => /.+@.+/.test(v)
	]
};

const getters = {
	form: (state: IForm): IForm => {
		return state;
	}
};

export default {
	state,
	getters
};
