import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';

const partners: IImageWithDescription[] = [
	{
		image: 'orange.svg',
		name: 'home.partners.orange.name',
		description: 'home.partners.orange.description'
	},
	{
		image: 'teltonika.svg',
		name: 'home.partners.teltonika.name',
		description: 'home.partners.teltonika.description'
	},
	{
		image: 'chmura.svg',
		name: 'home.partners.cloud.name',
		description: 'home.partners.cloud.description'
	},
	{
		image: 'chainlink.svg',
		name: 'home.partners.chainlink.name',
		description: 'home.partners.chainlink.description'
	},
	{
		image: 'ferrum.svg',
		name: 'home.partners.ferrum.name',
		description: 'home.partners.ferrum.description'
	},
	{
		image: 'geodb.svg',
		name: 'home.partners.geodb.name',
		description: 'home.partners.geodb.description'
	},
	{
		image: 'grenton.svg',
		name: 'home.partners.grenton.name',
		description: 'home.partners.grenton.description'
	}
];

export default partners;
