import { IHeader } from '@/interfaces/header.interface';

const state: IHeader = {
	toggleMenu: false,
	sticky: false,
	stickyPosition: 150
};

const getters = {
	header: (state: IHeader): IHeader => {
		return state;
	}
};

const mutations = {
	toggleMenu(): void {
		state.toggleMenu = !state.toggleMenu;
	},
	hideMenuOnClick(): void {
		if (state.toggleMenu) {
			mutations.toggleMenu();
		}
	},
	stickyHeader(): void {
		state.sticky = window.pageYOffset > state.stickyPosition;
	}
};

export default {
	state,
	getters,
	mutations
};
