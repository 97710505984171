import { ILink } from '@/interfaces/link.interface';

const menu: ILink[] = [
	{
		name: 'aboutUs',
		to: '/#discover'
	},
	{
		name: 'solutions',
		to: '/#solutions'
	},
	{
		name: 'application',
		to: '/#digital'
	},
	{
		name: 'technology',
		to: '/#technology'
	},
	// {
	// 	name: 'blog',
	// 	to: '/blog'
	// },
	{
		name: 'team',
		to: '/team'
	},
	{
		name: 'contact',
		to: '/contact'
	}
];

export default menu;
