import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {
	VApp,
	VContainer,
	VRow,
	VCol,
	VIcon,
	VForm,
	VTextField,
	VBtn,
	VLazy,
	VTabs,
	VTabsSlider,
	VTab,
	VTabsItems,
	VTabItem,
	VTextarea,
	VCheckbox
} from 'vuetify/lib/components';
import { Scroll, Intersect } from 'vuetify/lib/directives';

Vue.use(Vuetify, {
	components: {
		VApp,
		VContainer,
		VRow,
		VCol,
		VIcon,
		VForm,
		VTextField,
		VBtn,
		VLazy,
		VTabs,
		VTabsSlider,
		VTab,
		VTabsItems,
		VTabItem,
		VTextarea,
		VCheckbox
	},
	directives: {
		Scroll,
		Intersect
	}
});

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg'
	}
});
