<template>
	<section id="discover">
		<v-container>
			<v-row>
				<v-col cols="12">
					<div class="box text-right">
						<shared-heading
							heading="home.discover.heading"
							subheading="home.discover.subheading"
							additionalClasses="text-right">
						</shared-heading>
						<p
							v-html="$t('home.discover.header')"
							class="main-description"></p>
						<p>{{ $t('home.discover.body') }}</p>
					</div>
				</v-col>
			</v-row>
			<div class="images">
				<img width="607" height="607" :data-image="images.r" src="" alt="R" />
				<img width="618" height="690" :data-image="images.city" src="" alt="City" class="city" />
			</div>
		</v-container>
		<div class="circle"></div>
	</section>
</template>

<script lang="ts">
import SharedHeading from '@/components/shared/SharedHeading.vue';
import { gsap } from '@/main';
import Vue from 'vue';

export default Vue.extend({
	name: 'HomeDiscover',
	data: (): {
		circle: gsap.core.Tween;
		images: { [key: string]: string };
	} => ({
		circle: {} as gsap.core.Tween,
		images: {
			r:  require('../../assets/images/home/discover/r.webp'),
			city: require('../../assets/images/home/discover/city.webp')
		}
	}),
	components: {
		SharedHeading
	},
	mounted(): void {
		this.circle = gsap.to('#discover', {
			scrollTrigger: {
				trigger: '#discover .circle',
				start: 'top center',
				end: 'bottom center',
				toggleClass: 'active',
				once: true
			}
		});

		this.$store.dispatch('refreshScrollInstances', [
			this.circle.scrollTrigger
		]);
	},
	beforeDestroy() {
		this.$store.commit('killScrollInstances', [this.circle]);
	}
});
</script>

<style lang="scss">
@import 'src/styles/mixins';

#discover {
	@include z-index(relative, 1);
	overflow: hidden;

	.container {
		@include z-index(relative, 1);
		padding-top: 6rem;
		padding-bottom: 6rem;
		overflow: hidden;

		&::after {
			@include set-position(absolute, -10rem, -25rem, auto, auto);
			@include size(50rem, 50rem);
			content: '';
			background: radial-gradient(
				50% 50% at 50% 50%,
				#fffae6 2.08%,
				rgba(254, 251, 239, 0) 100%
			);
			mix-blend-mode: soft-light;
			filter: blur(3.5rem);
			z-index: 0;
		}
	}

	.row {
		@include z-index(relative, 1);
	}

	.heading {
		h3 {
			margin: 1rem 0 0;
		}
	}

	.col {
		@include display-flex(flex, wrap);
		justify-content: flex-end;
	}

	.box {
		@include margin-padding(0 4rem 0 0, 4rem);
		width: 52rem;
		background: radial-gradient(
				36.78% 55.72% at 5.95% 94.5%,
				rgba(255, 255, 255, 0.5) 0%,
				rgba(255, 255, 255, 0) 100%
			)
			rgba(255, 255, 255, 0.25);
		box-shadow: 0 4px 50px rgba(0, 0, 0, 0.08);
		backdrop-filter: blur(2.5rem);
		border-radius: 3rem;

		.main-description {
			@include font-size-weight(1.375rem, 400);
			line-height: 2.25rem;
			margin: 3.5rem 0;
		}

		p {
			font-size: 0.875rem;
			line-height: 1.875rem;
			margin: 0;
		}
	}

	.images {
		@include set-position(absolute, 0, auto, auto, 0);
		z-index: 0;

		.city {
			@include set-position(absolute, auto, auto, 0, -0.75rem);
		}
	}

	.circle {
		@include set-position(absolute, 0, auto, auto, 50%);
		@include size(0, 0);
		transform: translateX(-50%);
		background: #c7efec;
		border-radius: 50%;
		transition: width 1s cubic-bezier(0.785, 0.135, 0.15, 0.86),
			height 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
		z-index: 0;

		&.active {
			width: 250rem;
			height: calc(100% + 22rem);
			top: -22rem;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		padding-top: 13rem;

		.container {
			&::after {
				display: none;
			}
		}

		.box {
			@include margin-padding(0, 1.5rem);

			.main-description {
				font-size: 1.25rem;
				margin: 2rem 0;
			}
		}

		.images {
			position: relative;
			width: 30rem;
			margin-top: -4rem;
			margin-left: -0.75rem;

			img {
				max-width: 100%;
			}
		}
	}
}
</style>
