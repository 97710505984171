import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import goTo from 'vuetify/lib/services/goto';
import { Position } from 'vue-router/types/router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	// {
	// 	path: '/blog',
	// 	name: 'Blog',
	// 	component: () => import('../views/Blog.vue')
	// },
	{
		path: '/team',
		name: 'Team',
		component: () => import('../views/Team.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import('../views/Contact.vue')
	},
	{
		path: '/iot/:addr',
		name: 'IOT',
		component: () => import('../views/Iot.vue')
	},
	{
		path: '*',
		redirect: {
			name: 'Home'
		}
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: (
		to: Route,
		from: Route,
		savedPosition: Position | void
	): any => {
		let scrollTo: string | number = 0;

		if (to.hash) {
			scrollTo = to.hash;
		} else if (savedPosition) {
			scrollTo = savedPosition.y;
		}

		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(goTo(scrollTo, { offset: 77 }).then((r: number) => r));
			}, 100);
		});
	}
});

export default router;
