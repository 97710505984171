var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"education"}},[_c('v-container',[_c('shared-heading',{attrs:{"heading":"home.education.heading","subheading":"home.education.subheading"}}),_c('swiper',{attrs:{"slides-per-view":'auto',"space-between":30,"breakpoints":{
					320: {
						slidesPerView: 1,
						spaceBetween: 12
					},
					560: {
						slidesPerView: 2,
						spaceBetween: 12
					},
					960: {
						slidesPerView: 3,
						spaceBetween: 30
					}
				},"loop":""}},_vm._l((_vm.educations),function(education,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"slide-wrapper"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require(("@/assets/images/home/education/" + (education.image))),"alt":education.image}})]),_c('h4',[_vm._v(_vm._s(_vm.$t(education.name)))]),_c('p',[_vm._v(_vm._s(_vm.$t(education.description)))]),_c('a',{attrs:{"href":education.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('seeDetails'))+" "),_c('v-icon',[_vm._v(_vm._s(_vm.arrow))])],1)])])}),1),_c('v-lazy',{attrs:{"options":{
					threshold: 0
				}}},[_c('img',{staticClass:"background",attrs:{"width":"1263","height":"596","src":require("../../assets/images/home/education/background.png"),"alt":""}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }