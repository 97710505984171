<template>
	<section id="download">
		<v-container>
			<div v-if="!success">
				<v-form
					ref="form"
					v-model="form.valid"
					@submit.prevent="submitDataName">
					<v-row>
						<v-col cols="12">
							<v-text-field
								v-model="form.firstname"
								:rules="form.nameRules"
								:label="$t('home.download.form.name')"
								required
								outlined
								rounded
								>{{ name }}
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="form.email"
								:rules="form.emailRules"
								:label="$t('home.download.form.email')"
								required
								outlined
								rounded
								>{{ email }}
							</v-text-field>
							<p v-if="error" class="error--text" id="myDiv">
								{{ $t('home.download.form.error') }}
							</p>
						</v-col>
						<v-col cols="12" class="text-center">
							<v-btn class="white--text" rounded type="submit">
								{{ $t('home.download.form.button') }}
							</v-btn>
						</v-col>
					</v-row>
				</v-form>
			</div>
			<div v-else>
				<v-form>
					{{
						$t('home.download.form.success', {
							email: form.email
						})
					}}
				</v-form>
			</div>
			<div class="image">
				<img
					:data-image="images.background"
					src=""
					alt=""
					width="0"
					height="0" />
			</div>
			<a
				:href="eBooks[$i18n.locale]"
				@click="validate"
				target="_blank"
				ref="download"
				class="d-none"
				>eBook
			</a>
		</v-container>
		<shared-contact-box></shared-contact-box>
	</section>
</template>

<script lang="ts">
import Vue from 'vue';
import SharedContactBox from '@/components/shared/SharedContactBox.vue';
import { mapGetters } from 'vuex';
import eBooks from '@/data/home/ebook.data';
import axios from 'axios';

axios.defaults.headers.common['X-API_KEY'] = 'VUE_APP_API_KEY';

export default Vue.extend({
	name: 'HomeDownload',
	data: (): {
		success: boolean;
		email: string;
		name: string;
		images: { [key: string]: string };
		eBooks: { [key: string]: string };
		error: string;
	} => ({
		name: '',
		email: '',
		images: {
			background: require('../../assets/images/home/contact/background.webp')
		},
		eBooks,
		error: '',
		success: false
	}),
	components: {
		SharedContactBox
	},
	computed: {
		...mapGetters({
			form: 'form'
		})
	},
	methods: {
		async validate() {
			(this.$refs.form as Vue & { validate: () => boolean }).validate();
			if (this.form.valid) {
				await this.submitDataName();
			}
		},
		async submitDataName() {
			try {
				const response = await axios.post(
					'https://fontend-utils.skey.dev/newsletters/caruma/subscribe',
					{
						name: this.$store.getters.form.firstname.trim(),
						email: this.$store.getters.form.email.trim(),
						lang: this.$i18n.locale
					},
					{
						headers: {
							'X-API-KEY': process.env.VUE_APP_API_KEY
						}
					}
				);
				this.success = true;
			} catch (error) {
				this.success = false;
				this.error =
					'Email has already been subscribed to the newsletter';
				if ((error as any).response) {
					console.log((error as any).response.data.message);
				}
			}
		}
	}
});
</script>

<style lang="scss">
@import 'src/styles/mixins';

#download {
	position: relative;

	.image {
		@include set-position(absolute, 0, 0, 0, 0);
		overflow: hidden;
	}

	img {
		@include set-position(absolute, auto, auto, 0, 50%);
		transform: translateX(-50%);
		z-index: 0;
	}

	.v-form {
		@include z-index(relative, 1);
		@include margin-padding(-4rem auto 8rem, 3rem);
		width: 40rem;
		backdrop-filter: blur(2.5rem);
		border-radius: 3rem;
		box-shadow: 0 4px 50px rgba(0, 0, 0, 0.08);
		background: radial-gradient(
				36.78% 55.72% at 5.95% 94.5%,
				rgba(255, 255, 255, 0.5) 0%,
				rgba(255, 255, 255, 0) 100%
			)
			rgba(255, 255, 255, 0.25);

		.v-text-field--outlined {
			font-weight: 700;

			&:not(.v-input--is-focused):not(.v-input--has-state),
			&.v-input--is-focused:not(.error--text) {
				fieldset {
					color: var(--primary) !important;
				}

				label {
					color: #b1b9bd;
				}
			}

			fieldset {
				background-color: var(--white);
			}
		}

		.v-text-field__details {
			display: none;
			font-weight: 400;
		}

		.v-btn {
			@include size(15rem, 3.5rem !important);
			background-color: var(--secondary) !important;
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		height: auto;

		.v-form {
			margin-top: -2rem;
			margin-bottom: 10rem;
			width: 100%;
		}

		img {
			max-width: 100%;
		}
	}
}
</style>
