<template>
	<v-col class="solutions__wrapper">
		<v-row
			cols="12"
			v-for="(solution, i) in solutions"
			class="solutions__row"
			:key="i"
			:class="'tab-' + i">
			<v-col cols="12" class="solutions__header">
				<h3 class="text-uppercase">{{ $t(`home.solutions.${tabNames[i]}`) }}</h3></v-col
			>
			<v-col v-for="(sol, j) in solution" cols="12" sm="6" md="4" :key="j">
				<shared-box-with-icon
					catalog="solutions"
					:item="sol"></shared-box-with-icon>
			</v-col>
		</v-row>
	</v-col>
</template>

<script lang="ts">
import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';
import SharedBoxWithIcon from '@/components/shared/SharedBoxWithIcon.vue';

export default {
	name: 'SolutionsContainer',
	props: {
		solutions: new Array<IImageWithDescription[]>(),
		tabNames: new Array<string>()
	},
	components: {
		SharedBoxWithIcon
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

.solutions__wrapper {
  @include display-flex(flex, nowrap);
  overflow: hidden;

  .row {
    @include size-flex(100%);
    margin-left: 0;
    margin-right: 0;
  }
}

.solutions__row {
  .solutions__header {
    display: none;
  }

	h3 {
		@include font-size-weight(1.25rem, 700);
		color: var(--secondary);
		line-height: 4rem;
		letter-spacing: 0.05em;
	}

	.box-with-icon {
		h4 {
			line-height: 1.25rem;
			margin: 1rem 0;
		}

		p {
			margin: 0;
		}
	}
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .solutions__wrapper {
    display: block;
    overflow: hidden;

    .row {
      @include size-flex(100%);
      margin-left: -0.75rem;
      margin-right: -0.75rem;
    }
  }

  .solutions__row {
    .solutions__header {
      display: block;
    }
  }
}
</style>
