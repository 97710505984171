<template>
	<section id="digital">
		<v-container>
			<v-row>
				<v-col cols="12" md="6" class="column">
					<shared-heading
						heading="home.digital.heading"
						subheading="home.digital.subheading"
						additionalClasses="white--text text-left">
					</shared-heading>
					<p v-html="$t('home.digital.description')"></p>
					<!-- <img
              width="184"
              height="56"
						:data-image="images.oneKey"
						src=""
						alt="onekey"
						class="description-image" />
					<p>{{ $t('home.digital.download') }}</p> -->
					<div class="images">
						<a
							href="https://apps.apple.com/ag/app/one-key-by-caruma/id1604196352"
							target="_blank">
							<img
								width="136"
								height="41"
								:data-image="images.appStore"
								src=""
								alt="App Store" />
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=io.caruma.onekey&hl=en_US&gl=US"
							target="_blank">
							<img
								width="156"
								height="61"
								:data-image="images.googlePlay"
								src=""
								alt="Google Play" />
						</a>
					</div>
				</v-col>
				<div class="parallax">
					<img
						width="787"
						height="755"
						:data-image="images.circle"
						src=""
						alt="r"
						class="r" />
					<img
						width="756"
						height="794"
						:data-image="images.phone"
						src=""
						alt="Phone"
						class="phone" />
				</div>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="ts">
import Vue from 'vue';
import SharedHeading from '@/components/shared/SharedHeading.vue';
import { gsap } from '@/main';

export default Vue.extend({
	name: 'HomeDigital',
	data: (): {
		circle: gsap.core.Tween;
		phone: gsap.core.Tween;
		images: { [key: string]: string };
	} => ({
		circle: {} as gsap.core.Tween,
		phone: {} as gsap.core.Tween,
		images: {
			appStore: require('../../assets/images/home/digital/apple.svg'),
			googlePlay: require('../../assets/images/home/digital/google.svg'),
			oneKey: require('../../assets/images/home/digital/onekey.svg'),
			circle: require('../../assets/images/home/digital/r.webp'),
			phone: require('../../assets/images/home/digital/phone.webp')
		}
	}),
	components: {
		'shared-heading': SharedHeading
	},
	methods: {
		initializeParallax(target: string, yPosition: number): gsap.core.Tween {
			return gsap.to(target, {
				scrollTrigger: {
					trigger: '.parallax .r',
					start: '-25% center',
					end: 'bottom center',
					scrub: 0.5
				},
				y: yPosition,
				duration: 3
			});
		}
	},
	mounted(): void {
		this.circle = this.initializeParallax('.parallax .r', 35);
		this.phone = this.initializeParallax('.parallax .phone', -40);

		this.$store.dispatch('refreshScrollInstances', [
			this.circle.scrollTrigger,
			this.phone.scrollTrigger
		]);
	},
	beforeDestroy() {
		this.$store.commit('killScrollInstances', [this.circle, this.phone]);
	}
});
</script>

<style lang="scss">
@import 'src/styles/mixins';

#digital {
	background-color: var(--primary);
	color: var(--white);

	.row {
		position: relative;
		min-height: 42rem;
		padding: 4.5rem 0;
	}

	.column {
		@include z-index(relative, 1);
	}

	.heading {
		p {
			margin: 0;
		}

		h3 {
			margin: 0 0 2rem;
		}
	}

	.description-image {
		margin: 2rem 0;
	}

	.images {
		@include display-flex(flex, wrap);
		align-content: flex-start;

		a {
			display: inline-block;

			&:first-of-type {
				img {
					margin-top: 0.6rem;
				}
			}
		}
	}

	.parallax {
		@include set-position(absolute, 0, 0, 0, 0);
		z-index: 0;

		&::before,
		&::after {
			@include set-position(absolute, -3rem, 0, auto, 0);
			content: '';
			height: 3rem;
			background-color: var(--white);
			z-index: 2;
		}

		&::after {
			bottom: -3rem;
			top: auto;
			right: 25rem;
			z-index: 4;
		}

		img {
			position: absolute;
			right: 0;

			&.r {
				top: -2.5rem;
				z-index: 1;
			}

			&.phone {
				bottom: -2.5rem;
				z-index: 3;
			}
		}
	}

	p {
		line-height: 2rem;
	}

	@media #{map-get($display-breakpoints, 'md-and-down')} {
		.row {
			padding: 2rem 0;
		}

		.heading h3 {
			margin-bottom: 2rem;
		}

		.description-image {
			margin: 1rem 0;
		}

		.parallax {
			.r,
			.phone {
				max-width: 40em;
			}
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		text-align: center;

		.row {
			padding: 4.5rem 0 0;
		}

		.heading {
			text-align: center !important;
		}

		.description-image {
			margin: 3rem 0 1rem;
		}

		.images {
			justify-content: center;
		}

		.parallax {
			position: relative;
			width: 100%;

			&::before {
				display: none;
			}

			&::after {
				right: 0;
			}

			img {
				position: relative;

				&.r {
					display: none;
				}

				&.phone {
					display: block;
					// margin: 1rem auto 0;
					// max-width: 30rem;

					max-width: unset !important;
					position: relative;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}

	@media screen and (max-width: 560px) {
		.parallax {
			img {
				&.phone {
					max-width: 100%;
				}
			}
		}
	}
}
</style>
