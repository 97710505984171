import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';

const solutions: IImageWithDescription[][] = [
	[
		{
			image: 'panel.svg',
			name: 'home.solutions.panel.name',
			description: 'home.solutions.panel.description'
		},
		{
			image: 'keys-distribution.svg',
			name: 'home.solutions.keysDistribution.name',
			description: 'home.solutions.keysDistribution.description'
		},
		{
			image: 'management.svg',
			name: 'home.solutions.management.name',
			description: 'home.solutions.management.description'
		},
		{
			image: 'communication-standard.svg',
			name: 'home.solutions.communicationStandard.name',
			description: 'home.solutions.communicationStandard.description'
		},
		{
			image: 'tool.svg',
			name: 'home.solutions.tool.name',
			description: 'home.solutions.tool.description'
		},
		{
			image: 'multifunctions.svg',
			name: 'home.solutions.multiFunctions.name',
			description: 'home.solutions.multiFunctions.description'
		}
	],
	[
		{
			image: 'sharing-economy.svg',
			name: 'home.solutions.sharingEconomy.name',
			description: 'home.solutions.sharingEconomy.description'
		},
		{
			image: 'simple-keys.svg',
			name: 'home.solutions.simpleKeys.name',
			description: 'home.solutions.simpleKeys.description'
		},
		{
			image: 'revenue.svg',
			name: 'home.solutions.revenue.name',
			description: 'home.solutions.revenue.description'
		},
		{
			image: 'easy-payments.svg',
			name: 'home.solutions.easyPayments.name',
			description: 'home.solutions.easyPayments.description'
		},
		{
			image: 'feel-safe.svg',
			name: 'home.solutions.feelSafe.name',
			description: 'home.solutions.feelSafe.description'
		}
	],
	[
		{
			image: 'access.svg',
			name: 'home.solutions.easyAccess.name',
			description: 'home.solutions.easyAccess.description'
		},
		{
			image: 'sharing.svg',
			name: 'home.solutions.easySharing.name',
			description: 'home.solutions.easySharing.description'
		},
		{
			image: 'car.svg',
			name: 'home.solutions.cars.name',
			description: 'home.solutions.cars.description'
		},
		{
			image: 'renting-solutions.svg',
			name: 'home.solutions.rentingSolution.name',
			description: 'home.solutions.rentingSolution.description'
		}
	],
	[
		{
			image: 'helping.svg',
			name: 'home.solutions.helpingToRescue.name',
			description: 'home.solutions.helpingToRescue.description'
		}
	]
];

export default solutions;
