<template>
	<section id="solutions">
		<v-container>
			<v-row>
				<v-col cols="12">
					<shared-heading
						heading="home.solutions.heading"
						subheading="home.solutions.subheading">
					</shared-heading>
				</v-col>
				<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12">
					<v-tabs v-model="tab" centered :hide-slider="true">
						<v-tabs-slider></v-tabs-slider>
						<v-tab
							v-for="(name, index) in tabNames"
							:key="index"
							:href="`#tab-${index}`"
							@click="scrollTo(index)"
							>{{ $t(`home.solutions.${name}`) }}</v-tab
						>
					</v-tabs>
				</v-col>
				<solutions-container
					:solutions="solutions"
					:tab-names="tabNames"></solutions-container>
			</v-row>
		</v-container>
	</section>
</template>

<script lang="ts">
import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';
import SharedHeading from '@/components/shared/SharedHeading.vue';
import SolutionsContainer from '@/components/home/solutions/SolutionsContainer.vue';
import solutions from '@/data/home/solutions.data';
import Vue from 'vue';
import { gsap, ScrollTrigger } from '@/main';

export default Vue.extend({
	name: 'HomeSolutions',
	data: (): {
		tab: null;
		tabs: gsap.core.Tween;
		tabNames: string[];
		sections: gsap.core.Tween[];
		activeTab: number;
		solutions: IImageWithDescription[][];
	} => ({
		tab: null,
		tabs: {} as gsap.core.Tween,
		tabNames: ['cities', 'business', 'individuals', 'charities'],
		activeTab: 0,
		sections: [],
		solutions
	}),
	components: {
		SolutionsContainer,
		'shared-heading': SharedHeading
	},
	methods: {
		scrollTo(index: number): void {
			if (this.tabs.scrollTrigger) {
				const target = document.querySelector(
					`.tab-${index}`
				) as HTMLElement;

				if (target) {
					let totalScroll =
							this.tabs.scrollTrigger.end -
							this.tabs.scrollTrigger.start,
						totalMovement =
							(this.sections.length -
								(index > this.activeTab && index != 3
									? -1
									: 1)) *
							target?.offsetWidth;
					this.activeTab = index;

					gsap.to(window, {
						scrollTo: {
							y: Math.round(
								this.tabs.scrollTrigger.start +
									(target.offsetLeft / totalMovement) *
										totalScroll
							),
							autoKill: false
						},
						duration: 1
					});
				}
			}
		}
	},
	mounted(): void {
		const tabs = document.querySelectorAll('.v-tab');
		this.sections = gsap.utils.toArray('.solutions__row');

		ScrollTrigger.matchMedia({
			'(min-width: 996px)': () => {
				this.tabs = gsap.to(this.sections, {
					xPercent: -100 * (this.sections.length - 1),
					ease: 'none',
					scrollTrigger: {
						trigger: '#solutions',
						pin: true,
						scrub: 0.25,
						snap: 1 / (this.sections.length - 1),
						onUpdate: (self: ScrollTrigger) => {
							tabs.forEach((tab) =>
								tab.classList.remove('v-tab--active')
							);
							tabs[
                  Math.floor(self.progress * (tabs.length - 1))
							].classList.add('v-tab--active');
						},
						end: () =>
							'+=' +
							(
								document.querySelector(
									'.solutions__wrapper'
								) as HTMLElement
							)?.offsetWidth
					}
				});
			}
		});

		if (this.tabs.scrollTrigger) {
			this.$store.dispatch('refreshScrollInstances', [
				this.tabs.scrollTrigger
			]);
		}
		``;
	},
	beforeDestroy() {
		if (this.tabs.scrollTrigger) {
			this.$store.commit('killScrollInstances', [this.tabs]);
		}
	}
});
</script>

<style lang="scss">
@import 'src/styles/mixins';

#solutions {
	padding-top: 6rem;
	margin-bottom: 10rem;

	.heading {
		h3 {
			margin-bottom: 2rem;
		}
	}

	.box-with-icon {
		text-align: center;

		p {
			margin: 0 auto 1rem;
		}
	}

	.v-tabs-bar {
		height: auto;
	}

	.v-slide-group__content {
		margin-bottom: 3.5rem;

		&::after {
			@include set-position(absolute, auto, 0, 0, 0);
			content: '';
			border-bottom: 1px solid #d6d6d6;
		}

		.v-tab {
			@include font-size-weight(1.25rem, 700);
			color: rgba(#333, 0.85) !important;
			width: 20%;
			padding: 4.5rem 1rem;
			letter-spacing: 0.05em;
      position: relative;

      &::after {
        @include set-position(absolute, auto, 0, 0, auto);
        @include size(100%, 0.25rem);
        content: '';
        opacity: 0;
        transition: opacity 0.15s ease-in-out;
      }

			&.v-tab--active {
				color: var(--secondary) !important;

        &::after {
          background-color: var(--secondary);
          opacity: 1;
        }
			}
		}
	}

  @media screen and (max-height: 1100px) {
    .v-slide-group__content {
      margin-bottom: 1rem;

      .v-tab {
        padding: 2.5rem 1rem;
      }
    }
  }

  @media screen and (max-height: 950px) {
    .v-slide-group__content {
      margin-bottom:  0;

      .v-tab {
        padding: 1rem;
      }
    }
  }

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		margin-bottom: 6rem;

		.box-with-icon {
			text-align: left;

			p {
				margin: 0 0 1rem;
			}
		}
	}
}
</style>
