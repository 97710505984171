<template>
	<div class="home">
		<home-future v-intersect.once="loadImages"></home-future>
		<home-discover v-intersect.once="loadImages"></home-discover>
		<home-solutions v-intersect.once="loadImages"></home-solutions>
		<home-digital v-intersect.once="loadImages"></home-digital>
		<home-technology v-intersect.once="loadImages"></home-technology>
		<home-partners v-if="false"></home-partners>
		<home-education v-if="false"></home-education>
		<home-learn v-intersect.once="loadImages"></home-learn>
		<home-download></home-download>
	</div>
</template>

<script lang="ts">
import HomeFuture from '@/components/home/HomeFuture.vue';
import HomeDiscover from '@/components/home/HomeDiscover.vue';
import HomeSolutions from '@/components/home/HomeSolutions.vue';
import HomeDigital from '@/components/home/HomeDigital.vue';
import HomeTechnology from '@/components/home/HomeTechnology.vue';
import HomePartners from '@/components/home/HomePartners.vue';
import HomeEducation from '@/components/home/HomeEducation.vue';
import HomeLearn from '@/components/home/HomeLearn.vue';
import HomeDownload from '@/components/home/HomeDownload.vue';
import { mapMutations } from 'vuex';

export default {
	name: 'Home',
	components: {
		HomeFuture,
		HomeDiscover,
		HomeSolutions,
		HomeDigital,
		HomeTechnology,
		HomePartners,
		HomeEducation,
		HomeLearn,
		HomeDownload
	},
	methods: {
		...mapMutations({
			loadImages: 'loadImages'
		})
	}
};
</script>

<style lang="scss"></style>
