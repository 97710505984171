export default {
	menu: {
		aboutUs: 'About us',
		solutions: 'Solutions & services',
		application: 'Application',
		technology: 'Technology',
		blog: 'Blog',
		team: 'Team',
		contact: 'Contact'
	},
	home: {
		future: {
			subheading: 'Technologies: blockchain | NFT | iot | smart',
			heading: 'We open <br> the future',
			description:
				'Empowering Businesses with Blockchain Innovation and Utility NFTs.',
			watch: 'Discover',
			dpp: {
				title: 'Caruma DPP<br>(Digital Product Passport)',
				description:
					'By choosing Caruma DPP, you ensure an innovative and secure solution that complies with European regulations and is prepared for the future.',
				items: {
					item1: 'Easy Implementation',
					item2: 'Process Automatization',
					item3: 'Blockchain Security',
					item4: 'Data Verification'
				}
			}
		},
		discover: {
			subheading: 'discover caruma',
			heading: 'Who we are?',
			header: 'Caruma is a forward-thinking software house specializing in blockchain solutions and Web 3.0 technologies. We utilize blockchain, NFTs, IoT, and smart contracts to create solutions that enhance business efficiency and transparency.',
			body: 'Our partnership with SkeyNetwork enables us to deliver innovative solutions for decentralized applications and systems. These support secure data management and circular economy practices, including the Digital Product Passport. They enhance traceability and create new marketing opportunities, such as increased customer engagement and product authenticity verification through utility NFTs.'
		},
		solutions: {
			subheading: 'Blockchain Secured',
			heading: 'Solutions & services',
			cities: 'Cities',
			business: 'Business',
			individuals: 'Individuals',
			charities: 'Charities',
			panel: {
				name: 'Dedicate panel',
				description:
					'Access granting tool for businesses and city dwellers'
			},
			keysDistribution: {
				name: 'Distribution of keys',
				description: 'Access to any space, gate, building or door'
			},
			management: {
				name: 'Management',
				description: 'Facilitating the control of access to facilities'
			},
			communicationStandard: {
				name: 'Communication standard',
				description: 'Universal system for all devices'
			},
			tool: {
				name: 'A full-fledged Tool',
				description: 'Multiple locks controlled by only one app'
			},
			multiFunctions: {
				name: 'Multifunctionality',
				description: 'Opening locks and locating controlled objects'
			},
			sharingEconomy: {
				name: 'Sharing economy',
				description:
					'Stimulating sharing economy businesses through offering virtual keys'
			},
			simpleKeys: {
				name: 'Simple keys',
				description:
					'Simplifying keys management in offices, business zones, hotels, car fleets'
			},
			revenue: {
				name: 'Revenue',
				description: 'Bringing new revenue streams'
			},
			easyPayments: {
				name: 'Easy payments',
				description:
					'Enabling easy payments for dedicated services, at dedicated time'
			},
			feelSafe: {
				name: 'Feel safe',
				description:
					'Providing privacy, security and reliability of blockchain'
			},
			easyAccess: {
				name: 'Easy access',
				description:
					'Offering experience of easy access to any space having dedicated virtual key'
			},
			easySharing: {
				name: 'Easy sharing',
				description:
					'Sharing access to private spaces with friends and family'
			},
			cars: {
				name: 'Transport sharing',
				description:
					'Sharing access key with delivery people taxi drivers, urban services providers'
			},
			rentingSolution: {
				name: 'Renting solution',
				description:
					'Providing payments solution for car parking, renting scooters, bikes, etc.'
			},
			helpingToRescue: {
				name: 'Helping to rescue',
				description:
					"Providing access to emergency services to enter closed districts to save people's lives - “Rescue without Barriers” program"
			}
		},
		digital: {
			subheading: 'INNOVATIVE TOOLS',
			heading: 'Easy to use app',
			description:
				'The Blockchain-as-a-Service solution is a ready-to-use system crafted for immediate implementation. It is ideal for secure and efficient management of access to multiple devices without additional configuration. We deliver a variety of services, including Digital Product Passports and the integration of physical assets with digital ones through NFTs. Our ticketing system provides digital mementos based on NFTs. We also offer approaches that create new marketing channels, including Web 3.0, in addition to solutions that support the circular economy.',
			download: 'download the onekey app:'
		},
		technology: {
			subheading: 'bold step into future',
			heading: 'Technology',
			blockchain: 'Blockchain',
			blockchainSubheader: 'secure and unchangeable data log',
			internet: 'Internet of Things',
			internetSubheader: 'communication network of modern devices',
			internetDescription:
				'The Internet of Things is a network of connected devices that communicate with each other and make data available to the user via the Internet. - We improve the Internet of Things. Devices connected in our network receive an additional layer of security and anonymity. They become more effective and durable.',
			smartKeyTechnology: 'Powered by Skey Network Technology',
			smartKeySubheader:
				'Skey Network is a global world standard enabling technological compatibility of devices.',
			smartKeyDescription:
				'Skey Network is a universal communication system that combines IoT devices with Blockchain technology to create a Blockchain of Things. Skey Network combines technologies from Oracle, IoT, NFT, Cloud, Blockchain and DeFi. This system allows for full blockchain adoption and transmission of access keys between users and devices.',
			safety: {
				name: 'Security',
				description:
					'Blockchain guarantees the most secure level of encryption and ensures the insensitivity of confidential data.'
			},
			anonymity: {
				name: 'Anonymity',
				description:
					'guarantees the anonymity of sensitive data. You are just a long string of random characters in the system.'
			},
			cost: {
				name: 'Cost reduction',
				description:
					'effectively reduces the number of intermediaries. This significantly reduces costs while increasing the efficiency and security of data and transactions.'
			}
		},
		partners: {
			subheading: 'We cooperate with the best',
			heading: 'Partners',
			orange: {
				name: 'Orange Poland',
				description: 'card and service provider in cities'
			},
			teltonika: {
				name: 'teltonika',
				description: 'device provider'
			},
			cloud: {
				name: 'chmura krajowa',
				description: 'Cloud computing solutions provider'
			},
			chainlink: {
				name: 'CHAINLINK',
				description: 'Oracle and data provider from various services'
			},
			ferrum: {
				name: 'Ferrum network',
				description: 'specialization in deFi'
			},
			geodb: {
				name: 'geodb',
				description: 'managing geolocation data used in blockchain'
			},
			grenton: {
				name: 'grenton',
				description: 'card and service provider in cities'
			}
		},
		education: {
			subheading: 'learinnig is also a key to future',
			heading: 'Education',
			sgh: {
				name: 'SGH Warsaw School of Economics',
				description:
					'we are the patron of postgraduate studies at the Warsaw School of Economics. There, we support the implementation of the third edition of postgraduate studies on: "Blockchain: business, law, technology"'
			},
			piit: {
				name: 'Polska Izba Informatyki i Telekomunikacji',
				description: 'Member of the Chamber of PIIT'
			},
			uwm: {
				name: 'University of Warmia and Mazury in Olsztyn',
				description: ''
			},
			cracow: {
				name: 'Jagiellonian University in Cracow',
				description: 'Member of the Chamber of PIIT'
			}
		},
		learn: {
			subheading: 'Free E-book',
			heading: 'Learn about our solutions',
			description:
				'Thirsty for knowledge? Visit our Blog and download our free eBook. We present there 10 ways to use modern technologies in many different industries.'
		},
		download: {
			form: {
				name: 'Your name',
				email: 'Your e-mail address',
				error: 'Email has already been subscribed to the newsletter',
				success:
					'Thank you for subscribing to the newsletter. The link to download the e-book was sent to the email address you provided',
				button: 'Download our e-book'
			}
		}
	},
	team: {
		header: 'Meet our <br> team',
		maciej: {
			name: 'Maciej Bułkowski',
			description: 'Chief Executive Officer'
		},
		izabela: {
			name: 'Izabela Zawistowska',
			description: 'Development Director'
		},
		marcin: {
			name: 'Marcin Kowalski',
			description: 'Products & Implementation Director'
		}
	},
	blog: {
		header: 'Blog'
	},
	contact: {
		header: 'How can we help you?',
		form: {
			name: 'Your name',
			surname: 'Your surname',
			email: 'Your e-mail address',
			phone: 'Your phone number',
			message: 'Your message',
			terms: 'I have read the terms of Service and Privacy Policy.',
			send: 'Send'
		},
		validator: {
			noEmpty: 'Field is required.',
			email: 'Wrong e-mail address.',
			phoneMinLength: 'Minimum phone length is 7.',
			phoneMaxLength: 'Maximum phone length is 9.',
			phoneOnlyNumbers: 'Wrong phone number.',
			terms: 'Terms of Service and Privacy Policy must be accepted.'
		}
	},
	contactBox: {
		header: "Let's move into the <br> future together",
		description: "let's start our joint project",
		button: 'Contact'
	},
	footer: {
		copyright: '© 2023 Caruma sp. z o.o. All rights reserved.',
		links: {
			privacy: 'Privacy policy',
			terms: 'Terms of service'
		},
		information: {
			name: 'ul. ALEJA OBROŃCÓW TOBRUKU 7',
			city: '10-092 OLSZTYN',
			voivodeship: 'WARMIŃSKO-MAZURSKIE'
		},
		contact: 'Contact',
		backToTop: 'Back to top'
	},
	seeDetails: 'see details'
};
