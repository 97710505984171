import { gsap } from '@/main';

const mutations = {
	killScrollInstances(
		state: unknown,
		payload: (gsap.core.Tween | gsap.core.Timeline)[]
	): void {
		payload.forEach((item: gsap.core.Tween | gsap.core.Timeline) => {
			item.kill();
		});
	},
	loadImages(state: unknown, payload: IntersectionObserverEntry[]): void {
		const intersect = payload[0];

		if (intersect) {
			let nextSibling = intersect.target.nextElementSibling;

			if (nextSibling?.className === 'pin-spacer') {
				nextSibling = document.querySelector('#digital');
			}

			if (nextSibling || intersect.target.localName === 'footer') {
				const images = (
					nextSibling || intersect.target
				).querySelectorAll('[data-image]');

				images.forEach((image: Element) => {
					image.setAttribute(
						'src',
						image.getAttribute('data-image') as string
					);
				});
			}
		}
	}
};

const actions = {
	refreshScrollInstances(state: unknown, payload: ScrollTrigger[]): void {
		setTimeout(() => {
			payload.forEach((item: ScrollTrigger) => {
				item.refresh();
			});
		}, 250);
	}
};

export default {
	mutations,
	actions
};
