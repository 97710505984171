import Vue from 'vue';
import Vuex from 'vuex';

import Header from '@/modules/header.module';
import Form from '@/modules/form.module';
import ContactForm from '@/modules/contact.module';
import Scroll from '@/modules/scroll.module';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		Header,
		Form,
		ContactForm,
		Scroll
	}
});
