<template>
	<section id="education">
		<v-container>
			<shared-heading
				heading="home.education.heading"
				subheading="home.education.subheading">
			</shared-heading>
			<swiper
				:slides-per-view="'auto'"
				:space-between="30"
				:breakpoints="{
					320: {
						slidesPerView: 1,
						spaceBetween: 12
					},
					560: {
						slidesPerView: 2,
						spaceBetween: 12
					},
					960: {
						slidesPerView: 3,
						spaceBetween: 30
					}
				}"
				loop>
				<swiper-slide
					v-for="(education, index) in educations"
					:key="index">
					<div class="slide-wrapper">
						<div class="image">
							<img
								:src="
									require(`@/assets/images/home/education/${education.image}`)
								"
								:alt="education.image" />
						</div>
						<h4>{{ $t(education.name) }}</h4>
						<p>{{ $t(education.description) }}</p>
						<a :href="education.link" target="_blank"
							>{{ $t('seeDetails') }}
							<v-icon>{{ arrow }}</v-icon></a
						>
					</div>
				</swiper-slide>
			</swiper>
			<v-lazy
				:options="{
					threshold: 0
				}">
				<img
          width="1263"
          height="596"
					src="../../assets/images/home/education/background.png"
					alt=""
					class="background" />
			</v-lazy>
		</v-container>
	</section>
</template>

<script lang="ts">
import SharedHeading from '@/components/shared/SharedHeading.vue';
import educationData from '@/data/home/education.data';

import { Swiper, SwiperSlide } from 'swiper-vue2';
import { IEducation } from '@/interfaces/education.interface';
import { mdiChevronRight } from '@mdi/js';

export default {
	name: 'HomeEducation',
	data: (): { educations: IEducation[]; arrow: string } => ({
		arrow: mdiChevronRight,
		educations: educationData
	}),
	components: {
		Swiper,
		SwiperSlide,
		'shared-heading': SharedHeading
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

#education {
	padding: 10rem 0;
	overflow: hidden;

	.container {
		position: relative;
	}

	.heading {
		@include z-index(relative, 1);
	}

	.swiper-wrapper {
		padding: 2rem 0;
	}

	.swiper-slide {
		backdrop-filter: blur(2.5rem);
		width: 28rem;
		border-radius: 3rem;
		box-shadow: 0 4px 50px rgba(0, 0, 0, 0.08);
		height: auto;
		background: radial-gradient(
				36.78% 55.72% at 5.95% 94.5%,
				rgba(255, 255, 255, 0.5) 0%,
				rgba(255, 255, 255, 0) 100%
			)
			rgba(255, 255, 255, 0.25);

		.slide-wrapper {
			padding: 2.5rem;
		}

		.image {
			height: 8rem;
		}

		h4 {
			@include font-size-color(1.375rem, #333333);
			font-weight: 700;
			line-height: 1.875rem;
			margin: 0 0 1.75rem;
		}

		p {
			color: rgba(#0e2f42, 0.6);
			line-height: 1.625rem;
			margin-bottom: 3.75rem;
		}

		a {
			@include set-position(absolute, auto, auto, 2.5rem, 2.5rem);
			@include font-color-weight(#00a8db !important, 500);
			text-decoration: underline;
		}

		span {
			@include set-position(absolute, 50%, -1.25rem, auto, auto);
			@include size(1.25rem, 1.25rem);
			transform: translateY(-50%);
			color: #00a8db;
		}
	}

	.background {
		@include set-position(absolute, 5rem, 1rem, auto, auto);
		max-width: 65rem;
		z-index: 0;
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		padding: 3rem 0;

		.swiper-slide {
			.slide-wrapper {
				padding: 1.5rem;
			}
		}
	}
}
</style>
