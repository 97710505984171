import { IContactForm } from '@/interfaces/contactForm.interface';
import { vm } from '@/main';
import { LocaleMessages } from 'vue-i18n';

const state: IContactForm = {
	valid: false,
	firstname: '',
	lastname: '',
	nameRules: [
		(v: string): boolean | string | LocaleMessages =>
			!!v.trim() || vm.$t('contact.validator.noEmpty')
	],
	email: '',
	emailRules: [
		(v: string): boolean | string | LocaleMessages =>
			!!v.trim() || vm.$t('contact.validator.noEmpty'),
		(v: string): boolean | string | LocaleMessages =>
			/.+@.+/.test(v) || vm.$t('contact.validator.email')
	],
	phoneNumber: '',
	phoneRules: [
		(v: string): boolean | string | LocaleMessages =>
			!!v.trim() || vm.$t('contact.validator.noEmpty'),
		(v: string): boolean | string | LocaleMessages =>
			v.length >= 7 || vm.$t('contact.validator.phoneMinLength'),
		(v: string): boolean | string | LocaleMessages =>
			v.length < 10 || vm.$t('contact.validator.phoneMaxLength'),
		(v: string): boolean | string | LocaleMessages =>
			!isNaN(+v) || vm.$t('contact.validator.phoneOnlyNumbers')
	],
	message: '',
	terms: false,
	termsRules: [
		(v: string): boolean | string | LocaleMessages =>
			!!v || vm.$t('contact.validator.terms')
	]
};

const getters = {
	contactForm: (state: IContactForm): IContactForm => {
		return state;
	}
};

export default {
	state,
	getters
};
