import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from '@/data/locales/en';
import pl from '@/data/locales/pl';

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'pl',
	fallbackLocale: 'pl',
	messages: {
		en,
		pl
	}
});
