import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// import 'swiper/swiper-bundle.min.css';

Vue.config.productionTip = false;

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const vm = new Vue({
	vuetify,
	i18n,
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');

export { vm, gsap, ScrollTrigger };
