<template>
	<footer v-intersect.once="loadImages">
		<v-container>
			<v-row class="socials">
				<v-col cols="12" md="8" sm="6">
					<router-link to="/">
						<shared-logo aria-label="Caruma"></shared-logo>
					</router-link>
				</v-col>
				<v-col cols="12" md="4" sm="6">
					<ul class="socials">
						<li v-for="(social, index) in socials" :key="index">
							<a
								:href="social.link"
								target="_blank"
								:aria-label="social.link"
								><v-icon>{{ social.icon }}</v-icon></a
							>
						</li>
					</ul>
				</v-col>
			</v-row>
			<v-row class="information">
				<v-col cols="12" md="4">
					<ul>
						<li v-for="(link, index) in links" :key="index">
							<router-link :to="link.to">{{
								$t(`menu.${link.name}`)
							}}</router-link>
						</li>
					</ul>
				</v-col>
				<v-col cols="12" md="4">
					<a href="/Privacy_policy_Caruma.pdf" target="_blank"
						><u>{{ $t('footer.links.privacy') }}</u></a
					>
					<h4 class="text-uppercase">{{ $t('footer.contact') }}</h4>
					<a href="mailto:office@caruma.io">office@caruma.io</a>
				</v-col>
				<v-col cols="12" md="4">
					<p>
						{{ $t('footer.information.name') }} <br />
						{{ $t('footer.information.city') }} <br />
						{{ $t('footer.information.voivodeship') }} <br />
					</p>
					<p>
						NIP: 7393928871 <br />
						Regon: 383678314 <br />
						KRS 0000791675
					</p>
				</v-col>
			</v-row>
			<v-row class="copyright">
				<v-col cols="12" md="8">
					{{ $t('footer.copyright') }}
				</v-col>
				<v-col cols="12" md="4">
					<span @click="$vuetify.goTo(top)"
						>{{ $t('footer.backToTop') }}
						<v-icon>{{ arrow }}</v-icon></span
					>
				</v-col>
			</v-row>
			<img
				width="0"
				height="0"
				:data-image="images.background"
				src=""
				alt="Background"
				class="background" />
		</v-container>
	</footer>
</template>

<script lang="ts">
import { mdiArrowUpThin } from '@mdi/js';
import { ILink } from '@/interfaces/link.interface';
import { ISocial } from '@/interfaces/social.interface';
import menu from '@/data/menu.data';
import socials from '@/data/socials.data';
import SharedLogo from '@/components/shared/SharedLogo.vue';
import { mapMutations } from 'vuex';

export default {
	name: 'TheFooter',
	components: {
		SharedLogo
	},
	data: (): {
		socials: ISocial[];
		links: ILink[];
		arrow: string;
		top: number;
		images: { [key: string]: string };
	} => ({
		top: 0,
		arrow: mdiArrowUpThin,
		socials: socials,
		links: menu,
		images: {
			background: require('../assets/images/footer/background.webp')
		}
	}),
	methods: {
		...mapMutations({
			loadImages: 'loadImages'
		})
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

footer {
	@include font-size-color(0.75rem, var(--white));
	padding: 7.5rem 0 4.5rem;
	display: block;
	background-color: #03282b;
	position: relative;

	.background {
		@include set-position(absolute, 50%, auto, auto, 50%);
		@include max-size(100%, 100%);
		transform: translate(-50%, -50%);
		z-index: 0;
	}

	.row {
		@include z-index(relative, 1);

		&.information {
			margin-top: 6.5rem;
			margin-bottom: 6.5rem;

			a {
				display: block;
				margin-bottom: 1.5rem;
			}

			h4 {
				font-weight: 400;
				margin: 4.5rem 0 1.5rem;
			}

			p {
				color: var(--tertiary);
				margin-bottom: 2rem;
				line-height: 2;
			}
		}

		&.copyright {
			span {
				position: relative;
				cursor: pointer;

				.v-icon {
					@include set-position(
						absolute,
						-0.35rem,
						-1.5rem,
						auto,
						auto
					);
					color: var(--white);
				}
			}
		}

		&.socials {
			align-items: center;
		}
	}

	.logo {
		path {
			fill: var(--white);
		}
	}

	ul {
		list-style: none;

		&.socials {
			li {
				margin: 0 1.5rem 0 0;
				display: inline-block;

				&:last-of-type {
					margin: 0;
				}

				.v-icon {
					color: var(--white);
				}
			}
		}

		li {
			margin-bottom: 1.5rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		padding: 1rem 0;
		overflow: hidden;

		.background {
			@include max-size(70rem, 70rem);
			transform: none;
			left: auto;
			top: auto;
			right: -15rem;
			bottom: 2rem;
		}

		.row {
			&.information {
				margin-top: 1rem;
				margin-bottom: 1rem;

				p,
				a {
					margin-bottom: 1rem;
				}

				h4 {
					margin-top: 2.5rem;
					margin-bottom: 1rem;
				}
			}
		}

		ul {
			&.socials {
				text-align: right;
			}
		}
	}

	@media screen and (max-width: 600px) {
		.row.socials .col-12 {
			@include size-flex(50%);
		}

		ul.socials li {
			margin: 0 1rem 0 0;
		}
	}
}
</style>
