<template>
	<div id="app">
		<v-app v-scroll="stickyHeader">
			<the-navbar></the-navbar>
			<router-view />
			<the-footer></the-footer>
		</v-app>
	</div>
</template>

<script lang="ts">
import { mapGetters, mapMutations } from 'vuex';
import TheNavbar from '@/components/TheNavbar.vue';
import TheFooter from '@/components/TheFooter.vue';
import Vue from 'vue';

export default Vue.extend({
	components: { TheNavbar, TheFooter },
	computed: {
		...mapGetters({
			header: 'header'
		})
	},
	methods: {
		...mapMutations({
			stickyHeader: 'stickyHeader'
		}),
		setLanguage(): void {
			const lang = window.localStorage.getItem('lang');

			if (lang) {
				this.$i18n.locale = lang;
			}
		}
	},
	mounted(): void {
		this.setLanguage();
	}
});
</script>

<style lang="scss">
@import 'styles/global';
</style>
