<template>
	<div class="heading" :class="additionalClasses">
		<p>{{ $t(subheading) }}</p>
    <h3 v-html="$t(heading)"></h3>
  </div>
</template>

<script lang="ts">
export default {
	props: ['subheading', 'heading', 'additionalClasses'],
	name: 'SharedHeading'
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

.heading {
	text-align: center;

	&.white--text {
		p {
			color: var(--white);
		}
	}

	p {
		@include font-size-color(0.875rem, var(--subheading));
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 2px;
	}

	h3 {
		@include font-size-color(3.75rem, var(--prmary));
		margin: 2rem 0 6rem;
		line-height: 1;
		font-weight: 900;
	}

  @media screen and (max-height: 850px) {
    h3 {
      font-size: 3rem;
      line-height: 3rem;
    }
  }

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		text-align: left;

    h3 {
			font-size: 3rem;
			margin: 0 0 2rem;
		}
	}
}
</style>
