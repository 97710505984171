<template>
	<div class="box-with-icon">
		<div class="image">
			<img
          width="86"
          height="77"
				:src="
					require(`../../assets/images/home/${this.catalog}/${this.item.image}`)
				"
				:alt="item.image" />
		</div>
		<h4>
			{{ $t(item.name) }}
		</h4>
		<p>{{ $t(item.description) }}</p>
	</div>
</template>

<script lang="ts">
import { IImageWithDescription } from '@/interfaces/imageWithDescription.interface';

export default {
	name: 'SharedBoxWithIcon',
	props: {
		item: {} as IImageWithDescription,
		catalog: String
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

.box-with-icon {
	.image {
		height: 5rem;

		img {
			max-height: 4rem;
		}
	}

	h4 {
		@include font-size-weight(1.375rem, 700);
		line-height: 1.675rem;
		margin-bottom: 2rem;
	}

	p {
		max-width: 70%;
	}

  @media screen and (max-height: 1100px) {
    .image {
      height: 4rem;

      img {
        @include max-size(4.5rem, 3rem);
      }
    }

    h4 {
      font-size: 1.15rem !important;
      margin: 1rem 0 !important;
    }

    p {
      font-size: 0.875rem;
      max-width: 85%;
      margin-bottom: 0.5rem !important;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .image {
      height: 5rem;

      img {
        max-height: 4rem;
      }
    }

    h4 {
      font-size: 1.375rem !important;
      margin: 1rem 0 !important;
    }

    p {
      font-size: 1rem;
      max-width: 100%;
    }
  }
}
</style>
