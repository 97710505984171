export default {
	menu: {
		aboutUs: 'O nas',
		solutions: 'Rozwiązania i usługi',
		application: 'Aplikacja',
		technology: 'Technologia',
		blog: 'Blog',
		team: 'Zespół',
		contact: 'Kontakt'
	},
	home: {
		future: {
			subheading: 'Technologie: blockchain | NFT | iot | smart',
			heading: 'Otwieramy drzwi do przyszłości',
			description:
				'Wspieramy biznes innowacyjnymi rozwiązaniami blockchain i użytkowymi NFT.',
			watch: 'Zobacz',
			dpp: {
				title: 'Caruma DPP<br>(Digital Product Passport)',
				description:
					'Wybierając Caruma DPP, zapewniasz sobie innowacyjne i bezpieczne rozwiązanie, zgodne z europejskimi regulacjami, przygotowane na przyszłość.',
				items: {
					item1: 'Łatwa Implementacja',
					item2: 'Automatyzacja Procesu',
					item3: 'Bezpieczeństwo Blockchain',
					item4: 'Weryfikacja Danych'
				}
			}
		},
		discover: {
			subheading: 'odkryj Carumę',
			heading: 'Kim jesteśmy?',
			header: 'Caruma to nowoczesny software house specjalizujący się w rozwiązaniach opartych na technologii blockchain oraz technologiach Web 3.0. Wykorzystujemy blockchain, NFT, IoT i inteligentne kontrakty, aby tworzyć rozwiązania, które zwiększają efektywność i przejrzystość biznesu.',
			body: 'Partnerstwo z SkeyNetwork umożliwia nam dostarczanie innowacyjnych rozwiązań dla zdecentralizowanych aplikacji i systemów. Te rozwiązania wspierają bezpieczne zarządzanie danymi oraz praktyki gospodarki cyrkularnej, w tym Cyfrowy Paszport Produktu. Ułatwiają one również śledzenie łańcucha dostaw oraz tworzą nowe możliwości marketingowe, takie jak większe zaangażowanie klientów i weryfikacja autentyczności produktów za pomocą użytkowych NFT.'
		},
		solutions: {
			subheading: 'bezpieczeństwo blockchaina',
			heading: 'Rozwiązania i usługi',
			cities: 'Miasta',
			business: 'Firmy',
			individuals: 'odbiorcy indywidualni',
			charities: 'bezpieczeństwo publiczne',
			panel: {
				name: 'Specjalnie przeznaczony panel',
				description:
					'Narzędzie do zarządzania kontrolą dostępu dla firm i mieszkańców'
			},
			keysDistribution: {
				name: 'Dystrybucja kluczy',
				description:
					'Dystrybucja wirtualnych kluczy, które mogą otworzyć dowolną bramę, przestrzeń lub dowolny budynek'
			},
			management: {
				name: 'Zarządzanie',
				description:
					'Specjalnie przeznaczony panel ułatwiający zarządzanie obiektami miejskimi'
			},
			communicationStandard: {
				name: 'Standard komunikacji',
				description:
					'Ujednolicony standard komunikacji w technologii blockchain'
			},
			tool: {
				name: 'Pełnowartościowe narzędzie',
				description: 'Wiele blokad kontrolowanych tylko jedną aplikacją'
			},
			multiFunctions: {
				name: 'Multifunkcyjność',
				description:
					'Otwieranie blokad i lokalizowanie kontrolowanych obiektów'
			},
			sharingEconomy: {
				name: 'Nowe możliwości',
				description:
					'Technologia umożliwiająca ekonomie współdzielenia dla rozwoju przedsiębiorstw'
			},
			simpleKeys: {
				name: 'Obsługa biznesowa',
				description:
					'Wygodne i łatwe w obsłudze zarządzanie wirtualnymi kluczami w biurach, strefach biznesowych, hotelach i flotach samochodowych'
			},
			revenue: {
				name: 'Generowanie przychodów',
				description:
					'Wprowadzanie nowych modeli biznesowych i możliwości generowania przychodów'
			},
			easyPayments: {
				name: 'Redukcja kosztów',
				description:
					'Umożliwienie łatwych i szybkich płatności za dedykowane usługi wraz z redukcją pośredników i kosztów'
			},
			feelSafe: {
				name: 'Gwarancja jakości',
				description:
					'Zagwarantowanie prywatności, bezpieczeństwa i niezawodności technologii blockchain'
			},
			easyAccess: {
				name: 'Bezpieczna aplikacja',
				description:
					'Szybki, bezpieczny i łatwy dostęp do dowolnej przestrzeni dzięki dedykowanemu kluczowi w aplikacji'
			},
			easySharing: {
				name: 'Dzielenie dostępu',
				description:
					'Udostępnianie prywatnych przestrzeni czy urządzeń znajomym i rodzinie'
			},
			cars: {
				name: 'Efektywna dostawa',
				description:
					'Udostępnianie klucza dostawcom, taksówkarzom czy usługodawcom miejskim'
			},
			rentingSolution: {
				name: 'Obsługa płatności',
				description:
					'Rozwiązanie płatnicze za parkowanie samochodów, wypożyczanie skuterów, rowerów czy elektrycznych hulajnóg'
			},
			helpingToRescue: {
				name: 'Ratownictwo',
				description:
					'Natychmiastowy dostęp dla służb ratowniczych do zamkniętych dzielnic, barier, szlabanów, domofonów czy drzwi. Dostęp ten znacznie przyspiesza dotarcie do miejsca zdarzenia'
			}
		},
		digital: {
			subheading: 'INNOWACYJNE ROZWIĄZANIA',
			heading: 'Wygodna aplikacja',
			description:
				'Rozwiązanie Blockchain-as-a-Service to gotowe do użycia narzędzie, stworzone z myślą o natychmiastowej implementacji. Idealnie nadaje się do bezpiecznego i wydajnego zarządzania dostępem do wielu urządzeń bez dodatkowej konfiguracji. W ramach naszego wszechstronnego podejścia oferujemy również: Cyfrowe Paszporty Produktów, integrację zasobów fizycznych z cyfrowymi za pomocą NFT oraz system biletowy z cyfrowymi pamiątkami opartymi na NFT. Realizujemy także inne innowacyjne rozwiązania. Nasze usługi pomagają tworzyć nowe kanały marketingowe, w tym Web 3.0, oraz wspierają rozwój gospodarki cyrkularnej.',
			download: 'pobierz aplikację onekey:'
		},
		technology: {
			subheading: 'odważny krok w przyszłość',
			heading: 'Technologia',
			blockchain: 'Blockchain',
			blockchainSubheader: 'Bezpieczny i niezmienialny rejestr danych.',
			internet: 'Internet rzeczy',
			internetSubheader: 'sieć komunikacji nowoczesnych urządzeń',
			internetDescription:
				'Internet rzeczy to sieć połączonych urządzeń, które komunikują się ze sobą i udostępniają dane użytkownikowi poprzez Internet. Ulepszamy Internet rzeczy. Urządzenia połączone w naszej sieci, otrzymują dodatkową warstwę bezpieczeństwa i anonimowości. Stają się bardziej efektywne, skuteczne i wytrzymałe.',
			smartKeyTechnology: 'Napędzany technologią Skey Network',
			smartKeySubheader:
				'Skey Network to światowy standard umożliwiający technologiczną kompatybilność urządzeń.',
			smartKeyDescription:
				'Skey Network to uniwersalny system komunikacji, który łączy urządzenia IoT z technologią blockchain, tworząc Blockchain of Things. Skey Network łączy technologie Oracle, IoT, NFT, Cloud, blockchain i DeFi. System ten pozwala na pełną adopcję blockchain i przesyłanie kluczy dostępu między użytkownikami i urządzeniami.',
			safety: {
				name: 'Bezpieczeństwo',
				description:
					'Blockchain gwarantuje jeden z najbezpieczniejszych poziomów szyfrowania poufnych danych.'
			},
			anonymity: {
				name: 'Anonimowość',
				description:
					'Gwarantuje anonimowość danych wrażliwych. Jesteś tylko długim ciągiem losowych znaków w systemie.'
			},
			cost: {
				name: 'Redukcja kosztów',
				description:
					'Skutecznie zmniejsza ilość pośredników. Znacząco obniża to koszty, a jednocześnie zwiększa wydajność i bezpieczeństwo danych i transakcji.'
			}
		},
		partners: {
			subheading: 'współpracujemy z najlepszymi',
			heading: 'Partnerzy',
			orange: {
				name: 'Orange Poland',
				description: 'dostawca kart i usług w miastach'
			},
			teltonika: {
				name: 'teltonika',
				description: 'dostawca urządzeń'
			},
			cloud: {
				name: 'chmura krajowa',
				description: 'dostawca rozwiązań chmury obliczeniowej'
			},
			chainlink: {
				name: 'CHAINLINK',
				description: 'dostawca Oracle i danych z różnych usług'
			},
			ferrum: {
				name: 'Ferrum network',
				description: 'specjalizacja w deFi'
			},
			geodb: {
				name: 'geodb',
				description:
					'zarządzanie danymi geolokalizcyjnymi, wykorzystywanymi w blockchain'
			},
			grenton: {
				name: 'grenton',
				description: 'dostawca kart i usług w miastach'
			}
		},
		education: {
			subheading: 'nauka to klucz do przyszłości',
			heading: 'Edukacja',
			sgh: {
				name: 'Szkoła Główna Handlowa w Warszawie',
				description:
					'jesteśmy Mecenasem studiów podyplomowych na SGH w Warszawie. Wspieramy tam realizację III edycji studiów podyplomowych na temat: "Blockchain: biznes, prawo, technologia"'
			},
			piit: {
				name: 'Polska Izba Informatyki i Telekomunikacji',
				description: 'Członek Izby PIIT'
			},
			uwm: {
				name: 'Uniwersytet Warmińsko Mazurski w Olsztynie',
				description: ''
			},
			cracow: {
				name: 'Uniwersytet Jagielloński w Krakowie',
				description: 'Członek Izby PIIT'
			}
		},
		learn: {
			subheading: 'Darmowy E-book',
			heading: 'Dowiedz się więcej o rozwiązaniach',
			description:
				'Spragniony wiedzy? <b><u>Odwiedź naszego bloga</u></b> i <b><u>pobierz naszego darmowego e-booka</u></b>. Przedstawiamy tam 10 sposobów wykorzystania nowoczesnych technologii w wielu różnych branżach.'
		},
		download: {
			form: {
				name: 'Twoje imię',
				email: 'Twój e-mail',
				error: 'Email został już zapisany do newslettera',
				success:
					'Dziękujemy za zapisanie się do newslettera. Link do pobrania e-booka został wysłany na podany adres email',
				button: 'Pobierz e-booka!'
			}
		}
	},
	team: {
		header: 'Poznaj nasz <br> zespół',
		maciej: {
			name: 'Maciej Bułkowski',
			description: 'prezes'
		},
		izabela: {
			name: 'Izabela Zawistowska',
			description: 'Dyrektor ds. Rozwoju'
		},
		marcin: {
			name: 'Marcin Kowalski',
			description: 'Dyrektor ds. Wdrożeń i Produktów'
		}
	},
	blog: {
		header: 'Blog'
	},
	contact: {
		header: 'Jak możemy Ci pomóc?',
		form: {
			name: 'Twoje imię',
			surname: 'Twoje nazwisko',
			email: 'Twój e-mail',
			phone: 'Numer telefonu',
			message: 'Twoja wiadomość',
			terms: 'Oświadczam, że zapoznałem się regulaminem i akceptuję jego warunki.',
			send: 'Wyślij'
		},
		validator: {
			noEmpty: 'Pole nie może być puste.',
			email: 'Zły adres e-mail.',
			phoneMinLength: 'Minimalna ilość znaków to 7.',
			phoneMaxLength: 'Minimalna ilość znaków to 9.',
			phoneOnlyNumbers: 'Zły numer telefonu.',
			terms: 'Regulamin i warunki muszą zostać zaakceptowane.'
		}
	},
	contactBox: {
		header: 'Wejdźmy w <br> przyszłość razem',
		description: 'zacznijmy nasz wspólny projekt',
		button: 'Kontakt'
	},
	footer: {
		copyright: '© 2023 Caruma sp. z o.o. Wszystkie prawa zastrzeżone.',
		links: {
			privacy: 'Polityka Prywatności',
			terms: 'Warunki świadczenia usług'
		},
		information: {
			name: 'ul. ALEJA OBROŃCÓW TOBRUKU 7',
			city: '10-092 OLSZTYN',
			voivodeship: 'WARMIŃSKO-MAZURSKIE'
		},
		contact: 'Kontakt',
		backToTop: 'Powrót na górę'
	},
	seeDetails: 'zobacz szczegóły'
};
