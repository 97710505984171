<template>
	<section id="future">
		<v-container class="first">
			<v-row align-content="center">
				<v-col cols="12" md="6" class="column">
					<shared-heading
						heading="home.future.heading"
						subheading="home.future.subheading"
						additionalClasses="white--text text-left">
					</shared-heading>
					<p
						v-html="$t('home.future.description')"
						class="description"></p>
					<div class="text-right mr-10">
						<v-btn
							@click="$vuetify.goTo('#discover', { offset: 77 })"
							class="white--text"
							rounded
							>{{ $t('home.future.watch') }}
							<v-icon>{{ arrow }}</v-icon></v-btn
						>
					</div>
				</v-col>
				<div class="earth">
					<div class="image-wrapper">
						<div class="image-zoom">
							<img
								src="../../assets/images/home/future/buildings.webp"
								alt="Buildings"
								class="buildings" />
						</div>
						<img
							src="../../assets/images/home/future/tl.webp"
							alt="Vector"
							class="ctl" />
						<img
							src="../../assets/images/home/future/tr.webp"
							alt="Vector"
							class="ctr" />
						<img
							width="291"
							height="320"
							src="../../assets/images/home/future/bl.webp"
							alt="Vector"
							class="cbl" />
					</div>
				</div>
			</v-row>
		</v-container>
		<v-container class="second">
			<div class="dpp">
				<v-container>
					<img
						v-if="!isMobile()"
						src="../../assets/images/home/dpp/desktop.png"
						class="img" />

					<div class="dpp-html-content">
						<div class="dpp-texts">
							<h2
								class="title"
								v-html="$t('home.future.dpp.title')"></h2>
							<p
								class="description"
								v-html="$t('home.future.dpp.description')"></p>
						</div>
						<img
							v-if="isMobile()"
							src="../../assets/images/home/dpp/mobile.png"
							class="img-mobile" />
						<div class="background">
							<div class="content">
								<div class="item">
									<img
										src="../../assets/images/home/dpp/item1-top.svg" />
									<p
										class="text"
										v-html="
											$t('home.future.dpp.items.item1')
										"></p>
									<img
										src="../../assets/images/home/dpp/tick.svg" />
								</div>
								<div class="item">
									<img
										src="../../assets/images/home/dpp/item2-top.svg" />
									<p
										class="text"
										v-html="
											$t('home.future.dpp.items.item2')
										"></p>
									<img
										src="../../assets/images/home/dpp/tick.svg" />
								</div>
								<div class="item">
									<img
										src="../../assets/images/home/dpp/item3-top.svg" />
									<p
										class="text"
										v-html="
											$t('home.future.dpp.items.item3')
										"></p>
									<img
										src="../../assets/images/home/dpp/tick.svg" />
								</div>
								<div class="item">
									<img
										src="../../assets/images/home/dpp/item4-top.svg" />
									<p
										class="text"
										v-html="
											$t('home.future.dpp.items.item4')
										"></p>
									<img
										src="../../assets/images/home/dpp/tick.svg" />
								</div>
							</div>
						</div>
					</div>
				</v-container>
			</div>
		</v-container>
	</section>
</template>

<script lang="ts">
import SharedHeading from '@/components/shared/SharedHeading.vue';
import { mdiChevronRight } from '@mdi/js';

export default {
	name: 'HomeFuture',
	data: (): { arrow: string } => ({
		arrow: mdiChevronRight
	}),
	components: {
		'shared-heading': SharedHeading
	},
	methods: {
		isMobile(): boolean {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			);
		}
	}
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

#future {
	@include z-index(relative, 1);
	background-color: var(--primary);
	color: var(--white);

	.container.second {
		padding: 0px;
	}
	.dpp {
		background-color: var(--yellow);
		width: 100vw;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		min-height: unset !important;
		padding: 0px !important;
		padding-bottom: 60px !important;

		overflow: hidden;

		.container {
			padding: 0px 12px;
			position: relative;
			height: 1180px;

			@media (max-width: 1150px) {
				height: 1465px;
			}
		}

		.img {
			position: absolute;
			top: 0px;
			right: 0px;

			display: block;

			@media (max-width: 1265px) {
				left: 50%;
				transform: translateX(-50%);
			}
			@media (max-width: 1150px) {
				display: none;
			}
		}

		.img-mobile {
			display: none;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);

			@media (max-width: 1150px) {
				display: block;
			}
		}

		.dpp-html-content {
			display: flex;
			flex-direction: column;
			row-gap: 190px;
			align-items: flex-start;
			justify-content: flex-end;
			height: 100%;
			position: relative;
			z-index: 2;

			.dpp-texts {
				display: flex;
				flex-direction: column;
				row-gap: 31px;

				.title {
					color: #0c0c0c !important;

					font-family: Biennale !important;
					font-size: 40px !important;
					font-style: normal !important;
					font-weight: 900 !important;
					line-height: 44.5px !important;
					letter-spacing: -1.2px !important;
					// max-width: 540px;
					white-space: pre-wrap;
				}

				.description {
					color: #0c0c0c !important;

					font-family: Biennale !important;
					font-size: 16px !important;
					font-style: normal !important;
					font-weight: 400 !important;
					line-height: 24px !important;
					letter-spacing: 0.32px !important;
					max-width: 500px;
				}
			}

			.background {
				width: 100%;
				padding: 35px 0px;
				border-radius: 20px;
				border: 1px solid var(--white);
				background: rgba(255, 255, 255, 0.68);
				backdrop-filter: blur(16px);
				-webkit-backdrop-filter: blur(16px);

				.content {
					margin: auto;
					display: flex;
					justify-content: center;
					column-gap: 20px;
					row-gap: 20px;
					flex-wrap: wrap;

					.item {
						display: flex;
						flex-direction: column;
						align-items: center;
						row-gap: 16px;
						min-width: 160px;

						.text {
							color: #0e2f42;

							text-align: center;
							font-family: Biennale;
							font-size: 18px;
							font-style: normal;
							font-weight: 700;
							line-height: 19px; /* 105.556% */
							letter-spacing: 0.18px;
							max-width: 149px;
							margin-bottom: 0px;
						}
					}
					@media (max-width: 1150px) {
						column-gap: 0px;
						.item {
							width: 50%;
						}
					}
				}
			}

			@media (max-width: 1150px) {
				row-gap: 735px;
			}
		}
	}

	.row {
		position: relative;
		min-height: 100vh;
		padding: 15.625rem 0;

		&::after {
			@include set-position(absolute, 50%, auto, auto, 0);
			@include set-background(no-repeat, center center, contain);
			@include size(784.11px, 738.24px);
			content: '';
			transform: translateY(-50%);
			background-image: radial-gradient(
				50% 50% at 50% 50%,
				#fffae6 2.08%,
				rgba(254, 251, 239, 0) 100%
			);
			mix-blend-mode: soft-light;
			filter: blur(55px);
		}
	}

	.column {
		@include z-index(relative, 1);
	}

	.heading {
		h3 {
			font-size: 6rem;
			line-height: 6rem;
			margin: 2rem 0;
		}
	}

	.description {
		font-size: 1.5rem;
		line-height: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.v-btn {
		@include size(10rem, 3.5rem !important);
		background-color: var(--secondary) !important;
	}

	.earth {
		@include set-position(absolute, 0, 0, 0, 0);
		z-index: 0;

		.image-wrapper {
			@include set-position(absolute, 50%, 0, auto, auto);
			@include size(24rem, 24rem);
			transform: translateY(-50%);

			img {
				@include max-size(100%, 100%);
			}

			.image-zoom {
				@include set-position(absolute, 50%, 0, auto, auto);
				transform: translateY(-50%);
				overflow: hidden;
				border-radius: 50%;
			}

			.ctl {
				@include set-position(absolute, -7.5rem, auto, auto, -5.5rem);
				z-index: 0;
			}

			.ctr {
				@include set-position(absolute, -3rem, -5.875rem, auto, auto);
				z-index: 2;
			}

			.cbl {
				@include set-position(absolute, auto, auto, -7.5rem, -7rem);
				z-index: 1;
			}
		}
	}

	@media screen and (max-height: 850px) {
		.row {
			padding: 12.5rem 0 2.5rem;
		}

		.heading {
			h3 {
				font-size: 4.75rem;
				line-height: 4.75rem;
			}
		}

		.description {
			font-size: 1.15rem;
			line-height: 1.75rem;
		}
	}

	@media screen and (max-width: 1440px), screen and (max-height: 850px) {
		.earth .image-wrapper {
			@include size(18rem, 18rem);
			right: 5rem;
		}
	}

	@media screen and (max-height: 700px) {
		.heading {
			h3 {
				font-size: 3.75rem;
				line-height: 3.75rem;
			}
		}

		.earth .image-wrapper {
			@include size(15rem, 15rem);
		}
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		.row {
			min-height: auto;
			padding: 7.5rem 0 0;

			&::after {
				width: 100%;
			}
		}

		.heading {
			h3 {
				font-size: 3.625rem;
				line-height: 3.4375rem;
				margin: 2rem 0;
			}

			p {
				font-size: 0.8125rem;
			}
		}

		.description {
			font-size: 0.875rem;
			line-height: 1.33rem;
			letter-spacing: 0.01em;
		}

		.earth {
			position: relative;
			width: 100%;
			margin-top: 10rem;
			margin-bottom: -12rem;

			.image-wrapper {
				@include size(24rem, 24rem);
				position: relative;
				top: 0;
				right: 0;
				transform: translateY(0);
				margin: 0 auto;
			}
		}
	}
}
</style>
