<template>
	<v-container class="contact-box">
		<v-row align-content="center">
			<v-col cols="12" class="text-center white--text">
				<h3 v-html="$t('contactBox.header')"></h3>
				<p class="text-uppercase">{{ $t('contactBox.description') }}</p>
				<v-btn href="contact" class="white--text" text>{{
					$t('contactBox.button')
				}}</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'SharedContactBox'
};
</script>

<style lang="scss">
@import 'src/styles/mixins';

.contact-box {
	@include z-index(relative, 1);
	background-color: #135055;
	border-radius: 3rem;
	margin-bottom: -3rem;
	box-shadow: 0 0.75rem 1.75rem #012022;
	overflow: hidden;

	.row {
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;
		min-height: 23rem;
	}

	&::after {
		@include set-position(absolute, 0, auto, 0, 50%);
		@include size(75%, 100%);
		content: '';
		transform: translateX(-50%);
		background: radial-gradient(
			50% 50% at 50% 50%,
			#fffae6 2.08%,
			rgba(254, 251, 239, 0) 100%
		);
		mix-blend-mode: soft-light;
		filter: blur(55px);
		z-index: -1;
	}

	h3 {
		@include font-size-weight(3rem, 900);
		line-height: 2.75rem;
	}

	p {
		@include font-size-weight(0.875rem, 600);
		margin: 2rem 0 3rem !important;
		line-height: 1.25rem;
		letter-spacing: 2px;
	}

	.v-btn {
		@include size(14rem, 3.5rem !important);
		border-radius: 2.5rem;
		border: 1px solid #60a8aa;
	}

	@media #{map-get($display-breakpoints, 'sm-and-down')} {
		margin: 0;
		border-radius: 0;

		.row {
			padding-top: 3rem;
			padding-bottom: 3rem;
			min-height: auto;
		}

		h3 {
			font-size: 2rem;
			line-height: 1;
		}

		p {
			margin: 3rem 0 !important;
		}
	}
}
</style>
