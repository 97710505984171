import { mdiFacebook, mdiLinkedin, mdiTwitter } from '@mdi/js';
import { ISocial } from '@/interfaces/social.interface';

const socials: ISocial[] = [
	{
		link: 'https://www.facebook.com/carumaio/',
		icon: mdiFacebook
	},
	{
		link: 'https://mobile.twitter.com/caruma_io',
		icon: mdiTwitter
	},
	{
		link: 'https://pl.linkedin.com/company/caruma',
		icon: mdiLinkedin
	}
];

export default socials;
